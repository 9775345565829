var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.query._board ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("게시글 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("게시글 상세")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_vm.$route.params._board ? _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm.board.writer || {}).name,
      "label": "작성자",
      "placeholder": " ",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-datetime-field', {
    attrs: {
      "value": _vm.board.createdAt ? _vm.board.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "placeholder": " ",
      "hide-details": ""
    },
    on: {
      "input": function (value) {
        return _vm.board.createdAt = value;
      }
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("공지 (off/on)")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.board.isNotice,
      callback: function ($$v) {
        _vm.$set(_vm.board, "isNotice", $$v);
      },
      expression: "board.isNotice"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("내용")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "content",
      "rows": "10"
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1), _c('daum-postcode', {
    ref: "daumPostcode",
    on: {
      "change": _vm.changePostcode
    }
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }