var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800px"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.matching && _vm.consulting ? _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("상담일지")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-3"
  }, [_c('v-text-field', {
    attrs: {
      "label": "작성일자",
      "hide-details": "",
      "disabled": "",
      "readonly": "",
      "value": _vm.$dayjs(_vm.consulting.writedAt).format('YYYY-MM-DD HH:mm:ss')
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "셀러",
      "hide-details": "",
      "readonly": "",
      "value": _vm.matching.seller.companyName.ko
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "바이어",
      "hide-details": "",
      "readonly": "",
      "value": _vm.matching.buyer.companyName.ko
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "1.주력품목(셀러)",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.consulting.content1,
      callback: function ($$v) {
        _vm.$set(_vm.consulting, "content1", $$v);
      },
      expression: "consulting.content1"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "2.주력시장 및 타겟(셀러)",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.consulting.content2,
      callback: function ($$v) {
        _vm.$set(_vm.consulting, "content2", $$v);
      },
      expression: "consulting.content2"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "3.상담상품 정보",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.consulting.content3,
      callback: function ($$v) {
        _vm.$set(_vm.consulting, "content3", $$v);
      },
      expression: "consulting.content3"
    }
  }), _c('v-textarea', {
    staticClass: "mt-3",
    attrs: {
      "label": "4.거래 예상규모",
      "readonly": "",
      "hide-details": ""
    },
    model: {
      value: _vm.consulting.content4,
      callback: function ($$v) {
        _vm.$set(_vm.consulting, "content4", $$v);
      },
      expression: "consulting.content4"
    }
  }), _c('v-textarea', {
    staticClass: "mt-3",
    attrs: {
      "label": "5.상담내용",
      "readonly": "",
      "hide-details": ""
    },
    model: {
      value: _vm.consulting.content5,
      callback: function ($$v) {
        _vm.$set(_vm.consulting, "content5", $$v);
      },
      expression: "consulting.content5"
    }
  }), _c('div', {
    staticClass: "mt-3"
  }, [_c('span', {
    staticClass: "caption d-block"
  }, [_vm._v("취급의사")]), _c('v-radio-group', {
    staticClass: "mt-0",
    attrs: {
      "row": "",
      "dense": "",
      "readonly": "",
      "hide-details": ""
    },
    model: {
      value: _vm.consulting.intention,
      callback: function ($$v) {
        _vm.$set(_vm.consulting, "intention", $$v);
      },
      expression: "consulting.intention"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "있음",
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "label": "없음",
      "value": false
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-3"
  }, [_c('span', {
    staticClass: "caption"
  }, [_vm._v("만족도")]), _c('v-rating', {
    attrs: {
      "readonly": "",
      "value": _vm.consulting.satisfaction,
      "dense": ""
    }
  })], 1), _c('div', {
    staticClass: "mt-3"
  }, [_c('span', {
    staticClass: "caption d-block"
  }, [_vm._v("후속진행")]), _c('v-checkbox', {
    staticClass: "d-inline-block mt-0 mr-2",
    attrs: {
      "label": "이메일",
      "value": "email",
      "dense": ""
    },
    model: {
      value: _vm.consulting.followUp,
      callback: function ($$v) {
        _vm.$set(_vm.consulting, "followUp", $$v);
      },
      expression: "consulting.followUp"
    }
  }), _c('v-checkbox', {
    staticClass: "d-inline-block mt-0 mr-2",
    attrs: {
      "label": "샘플구매",
      "value": "sample",
      "dense": ""
    },
    model: {
      value: _vm.consulting.followUp,
      callback: function ($$v) {
        _vm.$set(_vm.consulting, "followUp", $$v);
      },
      expression: "consulting.followUp"
    }
  }), _c('v-checkbox', {
    staticClass: "d-inline-block mt-0 mr-2",
    attrs: {
      "label": "기업방문",
      "value": "visit",
      "dense": ""
    },
    model: {
      value: _vm.consulting.followUp,
      callback: function ($$v) {
        _vm.$set(_vm.consulting, "followUp", $$v);
      },
      expression: "consulting.followUp"
    }
  }), _c('v-checkbox', {
    staticClass: "d-inline-block mt-0 mr-2",
    attrs: {
      "label": "의사없음",
      "value": "none",
      "dense": ""
    },
    model: {
      value: _vm.consulting.followUp,
      callback: function ($$v) {
        _vm.$set(_vm.consulting, "followUp", $$v);
      },
      expression: "consulting.followUp"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "grey"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("닫기")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }