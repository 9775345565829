var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', [_c('h2', [_vm._v("회사연혁 First Section")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.setting.site.historyOne
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (props) {
        return [_c('draggable', {
          attrs: {
            "tag": "tbody"
          },
          model: {
            value: _vm.setting.site.historyOne,
            callback: function ($$v) {
              _vm.$set(_vm.setting.site, "historyOne", $$v);
            },
            expression: "setting.site.historyOne"
          }
        }, _vm._l(props.items, function (item, index) {
          return _c('tr', {
            key: item.id
          }, [_c('td', [_c('v-text-field', {
            attrs: {
              "hide-details": ""
            },
            model: {
              value: item.year,
              callback: function ($$v) {
                _vm.$set(item, "year", $$v);
              },
              expression: "item.year"
            }
          })], 1), _c('td', [_c('v-text-field', {
            model: {
              value: item.enContent,
              callback: function ($$v) {
                _vm.$set(item, "enContent", $$v);
              },
              expression: "item.enContent"
            }
          })], 1), _c('td', [_c('v-text-field', {
            model: {
              value: item.krContent,
              callback: function ($$v) {
                _vm.$set(item, "krContent", $$v);
              },
              expression: "item.krContent"
            }
          })], 1), _c('td', [_c('v-btn', {
            on: {
              "click": function ($event) {
                _vm.setting.site.historyOne.splice(_vm.setting.site.historyOne.indexOf(item), 1);
              }
            }
          }, [_vm._v("삭제")])], 1)]);
        }), 0)];
      }
    }])
  }), _c('v-btn', {
    on: {
      "click": function ($event) {
        return _vm.addData('One');
      }
    }
  }, [_vm._v("추가")])], 1), _c('v-col', [_c('h2', [_vm._v("회사연혁 Second Section")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.setting.site.historyTwo
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (props) {
        return [_c('draggable', {
          attrs: {
            "tag": "tbody"
          },
          model: {
            value: _vm.setting.site.historyTwo,
            callback: function ($$v) {
              _vm.$set(_vm.setting.site, "historyTwo", $$v);
            },
            expression: "setting.site.historyTwo"
          }
        }, _vm._l(props.items, function (item, index) {
          return _c('tr', {
            key: item.id
          }, [_c('td', [_c('v-text-field', {
            attrs: {
              "hide-details": ""
            },
            model: {
              value: item.year,
              callback: function ($$v) {
                _vm.$set(item, "year", $$v);
              },
              expression: "item.year"
            }
          })], 1), _c('td', [_c('v-text-field', {
            model: {
              value: item.enContent,
              callback: function ($$v) {
                _vm.$set(item, "enContent", $$v);
              },
              expression: "item.enContent"
            }
          })], 1), _c('td', [_c('v-text-field', {
            model: {
              value: item.krContent,
              callback: function ($$v) {
                _vm.$set(item, "krContent", $$v);
              },
              expression: "item.krContent"
            }
          })], 1), _c('td', [_c('v-btn', {
            on: {
              "click": function ($event) {
                _vm.setting.site.historyTwo.splice(_vm.setting.site.historyTwo.indexOf(item), 1);
              }
            }
          }, [_vm._v("삭제")])], 1)]);
        }), 0)];
      }
    }])
  }), _c('v-btn', {
    on: {
      "click": function ($event) {
        return _vm.addData('Two');
      }
    }
  }, [_vm._v("추가")])], 1)], 1), _c('v-btn', {
    on: {
      "click": _vm.putSetting
    }
  }, [_vm._v("저장")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }