var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user && _vm.user.type == 'seller' ? _c('v-card', {
    staticClass: "mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("상세정보")]), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "기업구분",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.kind,
      callback: function ($$v) {
        _vm.$set(_vm.user, "kind", $$v);
      },
      expression: "user.kind"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "value": _vm.user.category.name[_vm.locale],
      "label": "카테고리",
      "placeholder": " ",
      "hide-details": "",
      "readonly": ""
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "기업명(한국어)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.companyName.ko,
      callback: function ($$v) {
        _vm.$set(_vm.user.companyName, "ko", $$v);
      },
      expression: "user.companyName.ko"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "기업명(영어)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.companyName.en,
      callback: function ($$v) {
        _vm.$set(_vm.user.companyName, "en", $$v);
      },
      expression: "user.companyName.en"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "기업명(중국어)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.companyName.cn,
      callback: function ($$v) {
        _vm.$set(_vm.user.companyName, "cn", $$v);
      },
      expression: "user.companyName.cn"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "사업자등록번호",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.businessNumber,
      callback: function ($$v) {
        _vm.$set(_vm.user, "businessNumber", $$v);
      },
      expression: "user.businessNumber"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "시/도",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.sido[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.user.sido, _vm.locale, $$v);
      },
      expression: "user.sido[locale]"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "시/구/군",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.gugun[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.user.gugun, _vm.locale, $$v);
      },
      expression: "user.gugun[locale]"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "상세주소",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.companyAddress,
      callback: function ($$v) {
        _vm.$set(_vm.user, "companyAddress", $$v);
      },
      expression: "user.companyAddress"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "수출기업여부",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.isExportCompany,
      callback: function ($$v) {
        _vm.$set(_vm.user, "isExportCompany", $$v);
      },
      expression: "user.isExportCompany"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "대표자명",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.ceoName[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.user.ceoName, _vm.locale, $$v);
      },
      expression: "user.ceoName[locale]"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "대표자 이메일",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.ceoEmail,
      callback: function ($$v) {
        _vm.$set(_vm.user, "ceoEmail", $$v);
      },
      expression: "user.ceoEmail"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "대표자 연락처",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.ceoPhone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "ceoPhone", $$v);
      },
      expression: "user.ceoPhone"
    }
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }