<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.query._board" class="headline text-start mt-8">게시글 등록</div>
            <div v-else class="headline text-start mt-8">게시글 상세</div>

            <v-row>
                <v-col cols="8">
                    <v-card class="mt-4">
                        <v-card-title class="subtitle-2 pb-0">기본정보</v-card-title>
                        <v-card-text>
                            <v-row v-if="$route.params._board">
                                <v-col cols="6" class="py-0">
                                    <v-text-field :value="(board.writer || {}).name" label="작성자" placeholder=" " hide-details readonly disabled></v-text-field>
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-datetime-field :value="board.createdAt ? board.createdAt.toDateTime() : undefined" label="작성일" placeholder=" " hide-details @input="value => board.createdAt = value"></v-datetime-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-3">
                                <v-col cols="12" class="py-0">
                                    <v-text-field v-model="board.subject" label="제목" placeholder=" " hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card class="mt-4">
                        <v-card-title>
                            <span class="subtitle-2">공지 (off/on)</span>
                            <v-spacer/>
                            <v-switch v-model="board.isNotice" hide-details class="pa-0 ma-0"/>
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>

            <v-card class="mt-3">
                <v-card-title class="subtitle-2">내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="board.content" id="content" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

            <daum-postcode ref="daumPostcode" @change="changePostcode"></daum-postcode>

        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import DaumPostcode from '@/components/plugins/daum/daum-postcode.vue';
import NaverSmarteditor from '@/components/plugins/naver/naver-smarteditor.vue';

import { mdiTagPlus } from '@mdi/js';

export default {
    components: {
        VDatetimeField,
        DaumPostcode,
        NaverSmarteditor,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            board: {
                code: this.$route.query.code,
                category: undefined,

                subject: undefined,
                content: undefined,
                summary: undefined,
                period: undefined,
                createdAt: undefined,

                sequence: 1,
                
                isNotice: false,
            },
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                // if(this.$route.query._clone){
                //     this.board._id = undefined;
                
                //     if(this.board.thumb){
                //         this.thumb = await this.getResource(this.board.thumb);
                //         this.board.thumb = undefined;
                //     }
                //     for(var i in this.board.images){
                //         if(this.board.images[i]){
                //             this.images[i] = await this.getResource(this.board.images[i]);
                //             this.board.images[i] = undefined;
                //         }
                //     }
                // }

                if(this.$route.params._board){
                    var { board } = await api.console.boards.get({ _id: this.$route.params._board });
                    this.board = board;
                }
                
                if(!this.$route.params._board === undefined){
                    var { summary } = await api.console.boards.gets({
                        headers: { skip: 0, limit: 1 },
                        params: {
                            code: this.$route.query.code
                        }
                    });
                    this.board.sequence = summary.totalCount + 1;
                }

                this.ready = true;
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        async save(){
            try{
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                var { board } = this.board._id ? await api.console.boards.put(this.board) : await api.console.boards.post(this.board);

                alert(this.board._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file){
            try{ return URL.createObjectURL(file); }
            catch(error){
                return;
            }
        }
    },
}
</script>
