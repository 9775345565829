var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "info lighten-1",
      "dense": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "white--text subtitle-2"
  }, [_vm._v("카테고리")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "accent",
      "fab": "",
      "dark": "",
      "small": "",
      "absolute": "",
      "bottom": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.categoryDialog.open();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-card-text', [_vm.categories.length ? _c('v-draggable-treeview', {
    attrs: {
      "item-key": "_id",
      "children": "children",
      "group": "categories",
      "dense": ""
    },
    on: {
      "click": _vm.change
    },
    model: {
      value: _vm.categories,
      callback: function ($$v) {
        _vm.categories = $$v;
      },
      expression: "categories"
    }
  }) : _c('span', [_vm._v("등록된 카테고리가 없습니다."), _c('br'), _vm._v("카테고리를 등록해주세요")])], 1)], 1), _c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": !_vm.category._id
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("카테고리 기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "카테고리 코드(영문)",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.category.code,
      callback: function ($$v) {
        _vm.$set(_vm.category, "code", $$v);
      },
      expression: "category.code"
    }
  })], 1)], 1), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "카테고리 이름(한국어)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.category.name.ko,
      callback: function ($$v) {
        _vm.$set(_vm.category.name, "ko", $$v);
      },
      expression: "category.name.ko"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "카테고리 이름(영어)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.category.name.en,
      callback: function ($$v) {
        _vm.$set(_vm.category.name, "en", $$v);
      },
      expression: "category.name.en"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "카테고리 이름(중국어)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.category.name.cn,
      callback: function ($$v) {
        _vm.$set(_vm.category.name, "cn", $$v);
      },
      expression: "category.name.cn"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "grey darken-1",
      "disabled": !_vm.category._id || _vm.category.root ? true : false
    },
    on: {
      "click": function ($event) {
        return _vm.remove(_vm.category);
      }
    }
  }, [_vm._v("삭제")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": !_vm.category._id
    },
    on: {
      "click": function ($event) {
        return _vm.update(_vm.category);
      }
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1), _c('category-dialog', {
    ref: "categoryDialog",
    on: {
      "save": _vm.search
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }