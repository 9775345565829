var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm.user._id ? _c('span', [_vm._v("회원 상세보기")]) : _c('span', [_vm._v("회원 등록")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "아이디",
      "placeholder": " ",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  })], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "이름(담당자)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "연락처(담당자)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.phone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "이메일(담당자)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "회원등급",
      "items": _vm.levels,
      "item-text": "name",
      "item-value": "_id",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user._level,
      callback: function ($$v) {
        _vm.$set(_vm.user, "_level", $$v);
      },
      expression: "user._level"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "비밀번호",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "서브비밀번호",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.subPassword,
      callback: function ($$v) {
        _vm.$set(_vm.user, "subPassword", $$v);
      },
      expression: "user.subPassword"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-8"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "우편번호",
      "placeholder": " ",
      "hide-details": "",
      "readonly": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.DaumPostcode.open();
      }
    },
    model: {
      value: _vm.user.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.user, "postcode", $$v);
      },
      expression: "user.postcode"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "기본주소",
      "placeholder": " ",
      "hide-details": "",
      "readonly": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.DaumPostcode.open();
      }
    },
    model: {
      value: _vm.user.address1,
      callback: function ($$v) {
        _vm.$set(_vm.user, "address1", $$v);
      },
      expression: "user.address1"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "상세주소",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.address2,
      callback: function ($$v) {
        _vm.$set(_vm.user, "address2", $$v);
      },
      expression: "user.address2"
    }
  })], 1)], 1), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "label": "메모",
      "placeholder": " "
    },
    model: {
      value: _vm.user.note,
      callback: function ($$v) {
        _vm.$set(_vm.user, "note", $$v);
      },
      expression: "user.note"
    }
  })], 1)], 1), _c('buyer-details', {
    attrs: {
      "user": _vm.user
    }
  }), _c('seller-details', {
    attrs: {
      "user": _vm.user
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("주의회원")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "mt-0 pa-0",
    attrs: {
      "color": "red lighten-1",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.isWarning,
      callback: function ($$v) {
        _vm.$set(_vm.user, "isWarning", $$v);
      },
      expression: "user.isWarning"
    }
  })], 1), _c('v-card-text', {
    staticClass: "caption primary--text"
  }, [_vm._v(" * 주의회원 체크시 문자와 메일이 발숭되지 않으니 주의 부탁드립니다 ")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1), _c('daum-postcode', {
    ref: "DaumPostcode",
    on: {
      "input": function (_ref) {
        var postcode = _ref.postcode,
          address = _ref.address;
        _vm.user.postcode = postcode;
        _vm.user.address1 = address;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }