<template>
    <v-card v-if="user && user.type == 'buyer'" class="mt-3">
        <v-card-title class="">
            <v-row align="center">
                <v-col cols="auto" class="py-0 subtitle-2 font-weight-bold">
                    상세정보
                </v-col>
                <v-spacer/>
                <v-col cols="auto" class="py-0">
                    <v-select v-model="locale" :items="locales" item-text="text" item-value="value" label="언어" placeholder=" " hide-details class="mt-0"></v-select>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-text-field v-model="user.companyName[locale]" label="기업명" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.sido" label="시/도" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.gugun" label="시/구/군" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.companyAddress" label="상세주소" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.foundedAt" label="설립연도" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.businessAreas" label="사업분야" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.kind" label="바이어 유형" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.ceoName" label="대표자 이름" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.ceoPhone" label="대표자 연락처" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.ceoEmail" label="대표자 이메일" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.name" label="담당자 이름" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.phone" label="담당자 연락처" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.email" label="담당자 이메일" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.countryOfOrigin" label="회사 소재국가" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.activityAreas[locale]" label="바이어 활동지역" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.onlineCirculation" label="주요 유통망(온라인)"  placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.offlineCirculation" label="주요 유통망(오프라인)"  placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.aboutUs" label="회사소개" placeholder=" " hide-details class="mt-3"></v-text-field>
            
            <div class="mt-3">
                <div class="caption">관심상품군</div>
                <v-checkbox v-model="user.interestedGroups" v-for="category in categories" :key="category._id" :value="category._id" :label="category.name.ko" multiple dense hide-details class="mt-0 mr-3 d-inline-block"></v-checkbox>
            </div>
            <v-text-field v-model="user.sourcings" label="소싱희망상품" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.career" label="주요경력" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.siteUrl" label="홈페이지 주소" placeholder=" " hide-details class="mt-3"></v-text-field>
        </v-card-text>
    </v-card>
</template>

<script>
import api from "@/api";

export default {
    props: ["user"],
    data(){
        return {
            locale: "ko",
            locales: [
                { text: "한국어", value: "ko" },
                { text: "영어", value: "en" },
                { text: "중국어", value: "cn" }
            ],
            categories: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { categories } = await api.console.shop.categories.gets({
                params: { depth: 1 }
            });
            this.categories = categories;
        }
    }
}
</script>