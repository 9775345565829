<template>
    <v-dialog v-model="show" max-width="1280">
        <v-card v-if="excelSheet">
            <v-card-title class="subtitle-1">
                <span v-if="type == 'buyer'">바이어 업로드</span>
                <span v-if="type == 'seller'">셀러 업로드</span>
            </v-card-title>
            <v-divider/>
            <v-card-text class="pa-0">
                <v-data-table :items="users" :headers="headers.filter(header => header.type == type)">
                    <template v-slot:item.interestedGroups="{item}">
                        <span v-if="item.interestedGroups">{{ item.interestedGroups.map(category => category.name.ko).join(",") }}</span>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="grey" text @click="cancel">취소</v-btn>
                <v-btn color="primary" text @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import api from "@/api";
export default {
    data(){
        return {
            show: false,

            type: null,
            excelSheet: null,

            categories: [],

            headers: [
                ///////////////////////////////////////////////////
                // Buyer
                ///////////////////////////////////////////////////
                {
                    text: "기업명",
                    align: "center",
                    width: 200,
                    value: "companyName.ko",
                    type: "buyer"
                },
                {
                    text: "기업명(영문)",
                    align: "center",
                    width: 200,
                    value: "companyName.en",
                    type: "buyer"
                },
                {
                    text: "기업명(중문)",
                    align: "center",
                    width: 200,
                    value: "companyName.cn",
                    type: "buyer"
                },
                {
                    text: "시/도",
                    align: "center",
                    width: 200,
                    value: "sido",
                    type: "buyer"
                },
                {
                    text: "시/구/군",
                    align: "center",
                    width: 200,
                    value: "gugun",
                    type: "buyer"
                },
                {
                    text: "상세주소",
                    align: "center",
                    width: 200,
                    value: "companyAddress",
                    type: "buyer"
                },
                {
                    text: "설립연도",
                    align: "center",
                    width: 200,
                    value: "foundedAt",
                    type: "buyer"
                },
                {
                    text: "사업분야",
                    align: "center",
                    width: 200,
                    value: "businessAreas",
                    type: "buyer"
                },
                {
                    text: "바이어 유형",
                    align: "center",
                    width: 200,
                    value: "kind",
                    type: "buyer"
                },
                {
                    text: "대표자 이름",
                    align: "center",
                    width: 200,
                    value: "ceoName",
                    type: "buyer"
                },
                {
                    text: "대표자 이메일",
                    align: "center",
                    width: 200,
                    value: "ceoEmail",
                    type: "buyer"
                },
                {
                    text: "대표자 연락처",
                    align: "center",
                    width: 200,
                    value: "ceoPhone",
                    type: "buyer"
                },
                {
                    text: "담당자 이름",
                    align: "center",
                    width: 200,
                    value: "name",
                    type: "buyer"
                },
                {
                    text: "담당자 이메일",
                    align: "center",
                    width: 200,
                    value: "email",
                    type: "buyer"
                },
                {
                    text: "담당자 연락처",
                    align: "center",
                    width: 200,
                    value: "phone",
                    type: "buyer"
                },
                {
                    text: "회사 소재국가",
                    align: "center",
                    width: 200,
                    value: "countryOfOrigin",
                    type: "buyer"
                },
                {
                    text: "바이어 활동지역",
                    align: "center",
                    width: 200,
                    value: "activityAreas.ko",
                    type: "buyer"
                },
                {
                    text: "바이어 활동지역(영문)",
                    align: "center",
                    width: 200,
                    value: "activityAreas.en",
                    type: "buyer"
                },
                {
                    text: "바이어 활동지역(중문)",
                    align: "center",
                    width: 200,
                    value: "activityAreas.cn",
                    type: "buyer"
                },
                {
                    text: "주요 유통망(온라인)",
                    align: "center",
                    width: 200,
                    value: "onlineCirculation",
                    type: "buyer"
                },
                {
                    text: "주요 유통망(오프라인)",
                    align: "center",
                    width: 200,
                    value: "offlineCirculation",
                    type: "buyer"
                },
                {
                    text: "회사소개",
                    align: "center",
                    width: 200,
                    value: "aboutUs",
                    type: "buyer"
                },
                {
                    text: "관심상품군",
                    align: "center",
                    width: 200,
                    value: "interestedGroups",
                    type: "buyer"
                },
                {
                    text: "소싱희망상품",
                    align: "center",
                    width: 200,
                    value: "sourcings",
                    type: "buyer"
                },
                {
                    text: "주요경력",
                    align: "center",
                    width: 200,
                    value: "career",
                    type: "buyer"
                },
                {
                    text: "홈페이지 주소",
                    align: "center",
                    width: 200,
                    value: "siteUrl",
                    type: "buyer"
                },
                
                ///////////////////////////////////////////////////
                // Seller
                ///////////////////////////////////////////////////
                {
                    text: "기업구분",
                    align: "center",
                    width: 200,
                    value: "kind",
                    type: "seller"
                },
                {
                    text: "카테고리",
                    align: "center",
                    width: 200,
                    value: "category.name.ko",
                    type: "seller"
                },
                {
                    text: "기업명",
                    align: "center",
                    width: 200,
                    value: "companyName.ko",
                    type: "seller"
                },
                {
                    text: "기업명-영문",
                    align: "center",
                    width: 200,
                    value: "companyName.en",
                    type: "seller"
                },
                {
                    text: "기업명-중문",
                    align: "center",
                    width: 200,
                    value: "companyName.cn",
                    type: "seller"
                },
                {
                    text: "사업자등록번호",
                    align: "center",
                    width: 200,
                    value: "businessNumber",
                    type: "seller"
                },
                {
                    text: "시/도",
                    align: "center",
                    width: 200,
                    value: "sido.ko",
                    type: "seller"
                },
                {
                    text: "시/도(영문)",
                    align: "center",
                    width: 200,
                    value: "sido.en",
                    type: "seller"
                },
                {
                    text: "시/도(중문)",
                    align: "center",
                    width: 200,
                    value: "sido.cn",
                    type: "seller"
                },
                {
                    text: "시/구/군",
                    align: "center",
                    width: 200,
                    value: "gugun.ko",
                    type: "seller"
                },
                {
                    text: "시/구/군(영문)",
                    align: "center",
                    width: 200,
                    value: "gugun.en",
                    type: "seller"
                },
                {
                    text: "시/구/군(중문)",
                    align: "center",
                    width: 200,
                    value: "gugun.cn",
                    type: "seller"
                },
                {
                    text: "상세주소",
                    align: "center",
                    width: 200,
                    value: "companyAddress",
                    type: "seller"
                },
                {
                    text: "수출기업여부",
                    align: "center",
                    width: 200,
                    value: "isExportCompany",
                    type: "seller"
                },
                {
                    text: "담당자 성명",
                    align: "center",
                    width: 200,
                    value: "name",
                    type: "seller"
                },
                {
                    text: "담당자 이메일",
                    align: "center",
                    width: 200,
                    value: "email",
                    type: "seller"
                },
                {
                    text: "담당자 연락처",
                    align: "center",
                    width: 200,
                    value: "phone",
                    type: "seller"
                },
                {
                    text: "대표자명",
                    align: "center",
                    width: 200,
                    value: "ceoName.ko",
                    type: "seller"
                },
                {
                    text: "대표자명(영문)",
                    align: "center",
                    width: 200,
                    value: "ceoName.en",
                    type: "seller"
                },
                {
                    text: "대표자명(중문)",
                    align: "center",
                    width: 200,
                    value: "ceoName.cn",
                    type: "seller"
                },
                {
                    text: "대표자 이메일",
                    align: "center",
                    width: 200,
                    value: "ceoEmail",
                    type: "seller"
                },
                {
                    text: "대표자 연락처",
                    align: "center",
                    width: 200,
                    value: "ceoPhone",
                    type: "seller"
                },
            ]
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { categories } = await api.console.shop.categories.gets({
                headers: { limit: 0 },
                params: { depth: 1 }
            });
            this.categories = categories;
        },
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        },
        cancel(){
            this.excelSheet = [];
            this.close();
        },
        async save(){
            for(var user of this.users){
                await api.console.users.post(user);
            }
            this.$emit("save");
            this.close();
        },
    },
    computed: {
        users(){
            let users = [];
            let type = this.type;
            for(let row of this.excelSheet){
                switch(this.type){
                    case "buyer": {
                        let interestedGroups = row["관심상품군"] ? this.categories.filter(category => row["관심상품군"].split(",").includes(category.name.ko)) : [];

                        users.push({
                            type,
                            interestedGroups,
                            companyName: {
                                ko: row["기업명"],
                                en: row["기업명(영문)"],
                                cn: row["기업명(중문)"]
                            },
                            sido: row["시/도"],
                            gugun: row["시/구/군"],
                            companyAddress: row["상세주소"],
                            foundedAt: row["설립연도"],
                            businessAreas: row["사업분야"],
                            kind: row["바이어 유형"].replace(/[ ]/g, '').split(","),
                            ceoName: row["대표자 이름"],
                            ceoEmail: row["대표자 이메일"],
                            ceoPhone: row["대표자 연락처"],
                            name: row["담당자 이름"],
                            email: row["담당자 이메일"],
                            phone: row["담당자 연락처"],
                            countryOfOrigin: row["회사 소재국가"],
                            activityAreas: {
                                ko: row["바이어 활동지역"],
                                en: row["바이어 활동지역(영문)"],
                                cn: row["바이어 활동지역(중문)"]
                            },
                            onlineCirculation: row["주요 유통망(온라인)"],
                            offlineCirculation: row["주요 유통망(오프라인)"],
                            aboutUs: row["회사소개"],
                            sourcings: row["소싱희망상품"],
                            career: row["주요경력"],
                            siteUrl: row["홈페이지 주소"],
                        });
                        break;
                    }
                    case "seller": {
                        let category = this.categories.find(category => category.name.ko == row["카테고리"]);

                        users.push({
                            type,
                            category,
                            kind: row["기업구분"],
                            category,
                            companyName: {
                                ko: row["기업명"],
                                en: row["기업명-영문"],
                                cn: row["기업명-중문"],
                            },
                            businessNumber: row["사업자등록번호"],
                            sido: {
                                ko: row["시/도"],
                                en: row["시/도(영문)"],
                                cn: row["시/도(중문)"]
                            },
                            gugun: {
                                ko: row["시/구/군"],
                                en: row["시/구/군(영문)"],
                                cn: row["시/구/군(중문)"],
                            },
                            companyAddress: row["상세주소"],
                            isExportCompany: row["수출기업여부"] ? true : false,
                            name: row["담당자 성명"],
                            email: row["담당자 이메일"],
                            phone: row["담당자 연락처"],
                            ceoName: {
                                ko: row["대표자명"],
                                en: row["대표자명(영문)"],
                                cn: row["대표자명(중문)"],
                            },
                            ceoEmail: row["대표자 이메일"],
                            ceoPhone: row["대표자 연락처"],
                        });
                        break;
                    }
                }
            }
            return users;
        }
    }
}
</script>