<template>
    <v-card v-if="user && user.type == 'seller'" class="mt-3">
        <v-card-title class="subtitle-2 font-weight-bold">상세정보</v-card-title>
        <v-card-text>
            <v-text-field v-model="user.kind" label="기업구분" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field :value="user.category.name[locale]" label="카테고리" placeholder=" " hide-details class="mt-3" readonly></v-text-field>
            <v-text-field v-model="user.companyName.ko" label="기업명(한국어)" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.companyName.en" label="기업명(영어)" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.companyName.cn" label="기업명(중국어)" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.businessNumber" label="사업자등록번호" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.sido[locale]" label="시/도" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.gugun[locale]" label="시/구/군" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.companyAddress" label="상세주소" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.isExportCompany" label="수출기업여부" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.ceoName[locale]" label="대표자명" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.ceoEmail" label="대표자 이메일" placeholder=" " hide-details class="mt-3"></v-text-field>
            <v-text-field v-model="user.ceoPhone" label="대표자 연락처" placeholder=" " hide-details class="mt-3"></v-text-field>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ["user"],
    data(){
        return {
            locale: "ko",
            locales: [
                { text: "한국어", value: "ko" },
                { text: "영어", value: "en" },
                { text: "중국어", value: "cn" },
            ]
        };
    }
}
</script>