var render = function render(){
  var _vm$form, _vm$form$writer, _vm$form2, _vm$form2$writer, _vm$form3, _vm$form3$writer;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-form-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("폼메일 상세")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("기본정보")]), _c('v-card-text', {
    staticClass: "pb-6"
  }, [_vm.$route.params._form ? _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$writer = _vm$form.writer) === null || _vm$form$writer === void 0 ? void 0 : _vm$form$writer.name,
      "label": "작성자",
      "placeholder": " ",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt ? _vm.$dayjs(_vm.form.createdAt).format('YYYY-MM-DD') : undefined,
      "label": "작성일",
      "placeholder": " ",
      "dense": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : (_vm$form2$writer = _vm$form2.writer) === null || _vm$form2$writer === void 0 ? void 0 : _vm$form2$writer.phone,
      "label": "연락처",
      "placeholder": " ",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm$form3 = _vm.form) === null || _vm$form3 === void 0 ? void 0 : (_vm$form3$writer = _vm$form3.writer) === null || _vm$form3$writer === void 0 ? void 0 : _vm$form3$writer.email,
      "label": "이메일",
      "placeholder": " ",
      "dense": "",
      "hide-details": "",
      "readonly": ""
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "제작구분",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("내용")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "outlined": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1), ['interior-inquire'].includes(_vm.$route.query.code) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("답변")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "reply",
      "rows": "10"
    },
    model: {
      value: _vm.form.reply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reply", $$v);
      },
      expression: "form.reply"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1), _c('daum-postcode', {
    ref: "daumPostcode",
    on: {
      "change": _vm.changePostcode
    }
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }