<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.query._board" class="headline text-start mt-8">게시글 등록</div>
            <div v-else class="headline text-start mt-8">게시글 상세</div>

            <v-row>
                <v-col class="py-0">
                    <!-- <v-card class="mt-4">
                        <v-card-title class="subtitle-1">썸네일</v-card-title>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0">
                                    <v-img v-if="board.thumb" max-width="96" max-height="96" :src="createObjectURL(board.thumb)" contain></v-img>
                                    <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                        <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                                    </v-responsive>
                                </v-col>
                                <v-col class="py-0">
                                    <v-file-input v-model="board.thumb" label="썸네일 이미지" placeholder=" " class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card> -->

                    <v-card class="mt-4">
                        <v-card-title class="subtitle-1">이미지</v-card-title>
                        <v-card-text>
                            <v-row v-for="i in 20" :key="i" align="center" :class="{ 'mt-3': 1 < i}">
                                <v-col cols="auto" class="py-0">
                                    <v-img v-if="board.images[i-1]" max-width="96" max-height="96" :src="createObjectURL(board.images[i-1])" contain></v-img>
                                    <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                        <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                                    </v-responsive>
                                </v-col>
                                <v-col class="py-0">
                                    <v-file-input v-model="board.images[i-1]" :label="`이미지 ${i}`" placeholder=" " class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col class="py-0">
                    <v-card class="mt-4">
                        <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                        <v-card-text>
                            <v-text-field v-if="$route.params._board" :value="(board.writer || {}).name" label="작성자" placeholder=" " hide-details readonly disabled></v-text-field>
                            <v-datetime-field v-if="$route.params._board" :value="board.createdAt ? board.createdAt.toDateTime() : undefined" label="작성일" placeholder=" " hide-details @input="value => board.createdAt = value"></v-datetime-field>
                            <v-select v-model="board.category" :items="categories" item-text="name" item-value="name" label="카테고리" placeholder=" " hide-details class="mt-3"></v-select>
                            <v-text-field v-model="board.subject" label="프로젝트명" placeholder=" " hide-details class="mt-3" />
                            <v-text-field v-model="board.client" label="의뢰인" placeholder=" " hide-details class="mt-3" />
                            <v-text-field v-model="board.address" label="주소" placeholder=" " hide-details class="mt-3" />
                            <v-text-field v-model="board.area" label="면적" placeholder=" " hide-details class="mt-3" />
                            <v-text-field v-model="board.material" label="마감재" placeholder=" " hide-details class="mt-3" />
                            <v-text-field v-model="board.wall" label="벽" placeholder=" " hide-details class="mt-3" />
                            <v-text-field v-model="board.floor" label="바닥" placeholder=" " hide-details class="mt-3" />
                            <v-text-field v-model="board.ceiling" label="천정" placeholder=" " hide-details class="mt-3" />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-card class="mt-6">
                <v-card-title class="subtitle-2">내용1</v-card-title>
                <v-card-text>
                    <v-textarea v-model="board.content" id="content" rows="3" placeholder=" " hide-details outlined></v-textarea>
                </v-card-text>
            </v-card>
            <v-card class="mt-3">
                <v-card-title class="subtitle-2">내용2</v-card-title>
                <v-card-text>
                    <v-textarea v-model="board.content2" id="content" rows="3" placeholder=" " hide-details outlined></v-textarea>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

            <daum-postcode ref="daumPostcode" @change="changePostcode"></daum-postcode>

        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import DaumPostcode from '@/components/plugins/daum/daum-postcode.vue';
import NaverSmarteditor from '@/components/plugins/naver/naver-smarteditor.vue';

import { mdiTagPlus } from '@mdi/js';

export default {
    components: {
        VDatetimeField,
        DaumPostcode,
        NaverSmarteditor,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            categories: [
                { name: "Residence" },
                { name: "Retail" },
                { name: "Office" },
                { name: "Etc" },
            ],

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            board: {
                code: this.$route.query.code,
                category: "Residence",

                subject: undefined,
                content: null,
                content2: null,

                client: null,
                address: null,
                area: null,
                material: null,
                wall: null,
                floor: null,
                ceiling: null,

                thumb: undefined,
                images: new Array(20)
            },
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.$route.params._board){
                var { board } = await api.console.boards.get({ _id: this.$route.params._board });
                if(board.thumb) board.thumb = await api.getResource(board.thumb);
                for(var i in board.images){
                    if(board.images[i]) board.images[i] = await api.getResource(board.images[i]);
                }

                this.board = board;
            }
            
            if(!this.$route.params._board === undefined){
                var { summary } = await api.console.boards.gets({
                    headers: { skip: 0, limit: 1 },
                    params: {
                        code: this.$route.query.code
                    }
                });
                this.board.sequence = summary.totalCount + 1;
            }

            this.ready = true;
        },
        async save(){
            try{
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                var { board } = this.board._id ? await api.console.boards.put(this.board) : await api.console.boards.post(this.board);

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if(this.board.thumb) await api.console.boards.postThumb(board, this.board.thumb);
                for(var i in this.board.images){
                    if(this.board.images[i]) await api.console.boards.postImages(board, this.board.images[i], i);
                }

                alert(this.board._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file){
            try{ return URL.createObjectURL(file); }
            catch(error){
                return;
            }
        }
    },
}
</script>
