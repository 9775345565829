<template>
    <console-content max-width="1280">
        <v-row class="mt-3 mt-md-8 px-1" align="center">
            <v-col cols="auto" class="py-0 headline text-start">목록</v-col>
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn class="mr-1" color="secondary" rounded @click="showSearch = !showSearch">
                    <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                </v-btn>
            </v-col>
        </v-row>
        
        <v-expand-transition>
            <v-card v-show="showSearch" class="elevation-1 mt-3 mx-1">
                <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field label="label" placeholder=" " hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field label="label" placeholder=" " hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <v-col cols="12" sm="3" class="py-0">
                            <v-select v-model="filter.searchKey" :items="searchKeys" item-text="text" item-value="value" label="검색조건" placeholder=" " hide-details @input="filter.searchValue = null;" />
                        </v-col>
                        <v-col cols="12" sm="3" class="py-0">
                            <v-text-field v-model="filter.searchValue" label="검색어" placeholder=" " hide-details :disabled="!filter.searchKey" @keydown.enter="page = 1; search(true)" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded text>
                        <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>
        
        <v-row class="mt-6 px-1" align="end">
            <v-col cols="auto" class="py-0 subtitle-2">검색수 : {{ summary.totalCount.format() }}</v-col>
            <v-spacer />
            <v-col cols="auto" class="py-0">
                <v-btn color="white" class="green--text px-2 ml-1" @click="excel">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table :items="users" :headers="usersHeaders" hide-default-footer disable-sort disable-pagination class="mt-3 mx-1 elevation-1">
            <span slot="item.actions" slot-scope="{item}">
                <v-icon small class="mx-1" @click="edit(item)">mdi-pencil</v-icon>
                <v-icon small class="mx-1" @click="remove(item)">mdi-delete</v-icon>
            </span>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)"/>

        <v-btn fab fixed bottom right color="accent"><v-icon>mdi-pencil</v-icon></v-btn>

    </console-content>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        ConsoleContent
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            users: [],
            usersHeaders: [
                { text: "no", value: "no", align: "center", /* width: 10 */ },
                { text: "이름", value: "name", align: "center", /* width: 10 */ },
                { text: "actions", value: "actions", align: "center", /* width: 10 */ },
            ],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, users } = await api.console.users.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.users = users;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        create(){
            this.$router.push(`/console/boilerplates/create`);
        },
        edit(boilerplate){
            this.$router.push(`/console/boilerplates/${boilerplate._id}`);
        },
        async remove(boilerplate){
            if(confirm("삭제하시겠습니까?")){
                await api.console.boilerplate.delete(boilerplate);
                alert("삭제되었습니다");
                this.search(false);
            }
        },
        async excel(){
            let { users } = await api.console.users.gets({ headers: { limit: 0 }, params: this.filter });

            let rows = [];
            users.forEach(user => {
                rows.push({
                    "no": user.no,
                    "이름": user.name,
                });
            });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "엑셀시트이름");
            XLSX.writeFile(workbook, "엑셀파일이름.xlsx");
        }
    }
}
</script>