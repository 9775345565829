<template>
    <component :is="skin"/>
</template>
<script>
import BoardListArchive from "./BoardListArchive.vue";
import BoardListBasic from "./BoardListBasic.vue";

export default {
    components: {
        BoardListArchive,
        BoardListBasic
    },

    computed: {
        skin(){
            switch(this.$route.query.code){
                case "archive": return "BoardListArchive";
                default: return "board-list-basic";
            }
        }
    }
    
}
</script>