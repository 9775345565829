var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-responsive', [_c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("카테고리")]), _c('v-card-text', [_c('category-select', {
    attrs: {
      "categories": _vm.categories
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product._category,
      callback: function ($$v) {
        _vm.$set(_vm.product, "_category", $$v);
      },
      expression: "product._category"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "sm": "7"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('product-image', {
    attrs: {
      "label": "썸네일"
    },
    on: {
      "remove": _vm.removeThumb,
      "input": _vm.input
    },
    model: {
      value: _vm.product.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.product, "thumb", $$v);
      },
      expression: "product.thumb"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("상품 이미지")]), _c('v-card-text', [_c('draggable', {
    model: {
      value: _vm.product.images,
      callback: function ($$v) {
        _vm.$set(_vm.product, "images", $$v);
      },
      expression: "product.images"
    }
  }, [_c('transition-group', _vm._l(5, function (i, index) {
    return _c('product-image', {
      key: index,
      attrs: {
        "label": `슬라이드${i}`
      },
      on: {
        "remove": _vm.removeImage,
        "input": _vm.input
      },
      model: {
        value: _vm.product.images[index],
        callback: function ($$v) {
          _vm.$set(_vm.product.images, index, $$v);
        },
        expression: "product.images[index]"
      }
    });
  }), 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v(" 인증정보 ")]), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "인증구분",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.product.awardKind,
      callback: function ($$v) {
        _vm.$set(_vm.product, "awardKind", $$v);
      },
      expression: "product.awardKind"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3 mb-6",
    attrs: {
      "label": "인증번호",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.product.awardNumber,
      callback: function ($$v) {
        _vm.$set(_vm.product, "awardNumber", $$v);
      },
      expression: "product.awardNumber"
    }
  }), _c('v-date-field', {
    attrs: {
      "label": "선정일",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.product.awardAt,
      callback: function ($$v) {
        _vm.$set(_vm.product, "awardAt", $$v);
      },
      expression: "product.awardAt"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v(" URL 링크 ")]), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "판매 사이트",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.product.site,
      callback: function ($$v) {
        _vm.$set(_vm.product, "site", $$v);
      },
      expression: "product.site"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "홍페이지",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.product.homepage,
      callback: function ($$v) {
        _vm.$set(_vm.product, "homepage", $$v);
      },
      expression: "product.homepage"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "홍보마당 URL",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.product.promotion,
      callback: function ($$v) {
        _vm.$set(_vm.product, "promotion", $$v);
      },
      expression: "product.promotion"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "sm": "5"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-row', {
    attrs: {
      "align": "start"
    }
  }, [_c('v-col', {
    staticClass: "subtitle-2 font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 상품 정보 ")]), _c('v-spacer'), _c('v-col', [_c('v-select', {
    staticClass: "mt-0",
    attrs: {
      "items": _vm.locales,
      "item-text": "text",
      "item-value": "value",
      "label": "언어",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.locale,
      callback: function ($$v) {
        _vm.locale = $$v;
      },
      expression: "locale"
    }
  })], 1)], 1)], 1), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "상품명",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.name[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.product.name, _vm.locale, $$v);
      },
      expression: "product.name[locale]"
    }
  }), _c('v-textarea', {
    attrs: {
      "label": "주요특징",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.summary[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.product.summary, _vm.locale, $$v);
      },
      expression: "product.summary[locale]"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "제조국",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.country[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.product.country, _vm.locale, $$v);
      },
      expression: "product.country[locale]"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "사이즈",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.size[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.product.size, _vm.locale, $$v);
      },
      expression: "product.size[locale]"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "칼라",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.color[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.product.color, _vm.locale, $$v);
      },
      expression: "product.color[locale]"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "전성분",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.elements[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.product.elements, _vm.locale, $$v);
      },
      expression: "product.elements[locale]"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "주요판로처(온라인)",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.onlineDistributor[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.product.onlineDistributor, _vm.locale, $$v);
      },
      expression: "product.onlineDistributor[locale]"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "주요판로처(오프라인)",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.offlineDistributor[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.product.offlineDistributor, _vm.locale, $$v);
      },
      expression: "product.offlineDistributor[locale]"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "제조방법",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.manufacture,
      callback: function ($$v) {
        _vm.$set(_vm.product, "manufacture", $$v);
      },
      expression: "product.manufacture"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "제조업체",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.company,
      callback: function ($$v) {
        _vm.$set(_vm.product, "company", $$v);
      },
      expression: "product.company"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "소비자가(KRW)",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.price,
      callback: function ($$v) {
        _vm.$set(_vm.product, "price", $$v);
      },
      expression: "product.price"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "판매가(KRW)",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.salePrice,
      callback: function ($$v) {
        _vm.$set(_vm.product, "salePrice", $$v);
      },
      expression: "product.salePrice"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "매출액(KRW)",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.salesAmount,
      callback: function ($$v) {
        _vm.$set(_vm.product, "salesAmount", $$v);
      },
      expression: "product.salesAmount"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "수출액($)",
      "placeholder": " "
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.product.exportsAmount,
      callback: function ($$v) {
        _vm.$set(_vm.product, "exportsAmount", $$v);
      },
      expression: "product.exportsAmount"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }