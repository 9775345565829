<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%" height="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">탈퇴회원 목록</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <v-card v-show="showSearch" class="elevation-1 mx-auto">
                        <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                        <v-card-text class="py-0">
                            <v-row class="mt-4" align="center">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="filter.name" dense hide-details type="text" label="사용자이름" placeholder=" " />
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="filter.phone" dense hide-details type="text" label="연락처" placeholder=" "/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" rounded text @click="search"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>

                <v-data-table :headers="usersHeaders" :items="users" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-slot:item.createdAt="{ item }">
                        {{ item.createdAt ? item.createdAt.toDateTime() : null }}
                    </template>
                    <template v-slot:item.phone="{ item }">
                        {{ item.phone ? item.phone.phoneNumberFormat() : null }}
                    </template>
                    <template v-slot:item.level="{ item }">
                        {{ item.level ? item.level.name : '-' }}
                    </template>
                    <template v-slot:item.warning="{ item }">
                        <v-row justify="center" align="center">
                            <v-col cols="auto" class="pa-0">
                                <v-switch v-model="item.warning" color="red lighten-1" hide-details dens class="mt-0"></v-switch>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small @click="edit(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12 mx-4" @input="search"></v-pagination>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
export default {
    data() {
        return {
            showSearch: true,

            page: 1,
            pageCount: 0,
            limit: 10,
            filter: {
                name: this.$route.query.name,
                phone: this.$route.query.phone,
                _level: this.$route.query._level,
                warning: undefined,
                withdrawal: true
            },

            users: [],
            usersHeaders: [{
                    text: '사용자 이름',
                    align: 'center',
                    width: 200,
                    value: 'name',
                },
                {
                    text: '연락처',
                    align: 'center',
                    width: 128,
                    value: 'phone'
                },
                {
                    text: '이메일',
                    align: 'center',
                    width: 128,
                    value: 'email'
                },
                {
                    text: '등급',
                    align: 'center',
                    width: 140,
                    value: 'level'
                },
                {
                    text: '주의회원',
                    width: 100,
                    align: 'center',
                    value: 'warning'
                },
                {
                    text: "탈퇴사유",
                    align: "center",
                    value: "withdrawalReason"
                },
                {
                    text: "남기신말",
                    align: "center",
                    value: "withdrawalMessage"
                },
                {
                    text: '가입일자',
                    align: 'center',
                    width: 160,
                    value: 'createdAt'
                },
            ],

            warningsItems: [
                { text: "전체", value: undefined },
                { text: "주의회원", value: true },
                { text: "미주의회원", value: false }
            ]

        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                this.search();
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async search(){
            try{
                var { summary, users } = await api.console.users.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.filter
                });

                this.users = users;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        edit(user){
            this.$router.push({
                name: this.$route.name,
                query: {
                    _user: user._id
                }
            });
        },
    }
}
</script>
