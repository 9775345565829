<template>
<v-menu v-model="showTimePicker" ref="timeMenu" :close-on-content-click="false" :return-value.sync="time" transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator>
        <v-menu v-model="showDatePicker" ref="dateMenu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator>
                <v-text-field v-model="datetime" :label="label" :placeholder="placeholder" :dense="dense" :hide-details="hideDetails" append-icon="event" :clearable="clearable" readonly @click="showDatePicker = true"></v-text-field>
            </template>
            <v-date-picker v-model="date" :type="type" no-title scrollable @input="$refs.dateMenu.save(date); showDatePicker = false; showTimePicker = true"></v-date-picker>
        </v-menu>
    </template>
    <v-time-picker v-if="showTimePicker" v-model="time" no-title scrollable @click:minute="$refs.timeMenu.save(time); showTimepicker = false; datetime = [date, time].join(' ')"></v-time-picker>
</v-menu>
</template>

<script>
export default {
    props: {
        value: { type: String, default: undefined },

        type: { type: String, default: 'date' },
        label: { type: String, default: undefined },
        placeholder: { type: String, default: undefined },
        dense: { type: Boolean, default: false },
        hideDetails: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false }
    },
    data(){
        return {
            datetime: this.$props.value,
            
            date: this.$props.value.split(" ")[0],
            time: this.$props.value.split(" ")[1],

            showDatePicker: false,
            showTimePicker: false,
        };
    },
    methods: {
        test(on){
            console.log({on});
        }
    },
    watch: {
        value(){
            this.datetime = this.value;
            this.date = this.value.split(" ")[0];
            this.time = this.value.split(" ")[1];
        },
        datetime(){
            this.$emit('input', new Date(this.datetime));
        }
    }
}
</script>
