<template>
<v-container fluid fill-height>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%">
            <v-row class="mt-4">
                <v-col cols="12" md="6">
                    <v-card>
                        <v-toolbar color="info lighten-1" dense>
                            <v-toolbar-title class="white--text subtitle-2">카테고리</v-toolbar-title>

                            <v-spacer></v-spacer>
                            <v-btn color="accent" fab dark small absolute bottom right @click="$refs.categoryDialog.open()">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-toolbar>

                        <v-card-text>
                            <v-draggable-treeview v-if="categories.length" v-model="categories" item-key="_id" children="children" group="categories" @click="change" dense></v-draggable-treeview>
                            <span v-else>등록된 카테고리가 없습니다.<br>카테고리를 등록해주세요</span>
                        </v-card-text>

                    </v-card>

                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col md="auto">

                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card :disabled="!category._id">
                        <v-card-title class="subtitle-1">카테고리 기본정보</v-card-title>
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col class="py-0">
                                    <v-text-field v-model="category.code" label="카테고리 코드(영문)" placeholder=" " dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-text-field v-model="category.name.ko" label="카테고리 이름(한국어)" placeholder=" " hide-details class="mt-3"></v-text-field>
                            <v-text-field v-model="category.name.en" label="카테고리 이름(영어)" placeholder=" " hide-details class="mt-3"></v-text-field>
                            <v-text-field v-model="category.name.cn" label="카테고리 이름(중국어)" placeholder=" " hide-details class="mt-3"></v-text-field>
                        </v-card-text>
                    </v-card>

                    <v-row>
                        <v-col md="auto">
                            <v-btn color="grey darken-1" class="white--text" @click="remove(category)" :disabled="!category._id || category.root ? true : false">삭제</v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col md="auto">
                            <v-btn color="primary" @click="update(category)" :disabled="!category._id">저장</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <category-dialog ref="categoryDialog" @save="search"></category-dialog>
        </v-responsive>
    </v-layout>
</v-container>
</template>

<script>
import api from "@/api";
import CategoryDialog from "@/components/console/shop/categories/category-dialog.vue";
import CategorySelect from '@/components/console/shop/categories/category-select.vue';

export default {
    components: {
        CategoryDialog,
        CategorySelect,
    },
    data() {
        return {
            dialog: false,

            loading: true,

            headers: [],
            categories: [],
            category: {
                banners: [],
                name: {
                    ko: null,
                    en: null,
                    cn: null,
                }
            },
            editItem: null,
            defaultItem: {
                name: null,
                code: null,
            },

            banner: null,
            pickup: null,

            locales: [
                { text: "한국어", value: "ko" },
                { text: "영어", value: "en" },
                { text: "중국어", value: "cn" }
            ]
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
            this.loading = false;
        },

        async search(){
            let { categories } = await api.console.shop.categories.gets({ params: { depth: 1 } });
            this.categories = categories;
        },

        async change(category){
            try{
                if(category.icon && !this.createObjectURL(category.icon)) category.icon = await api.getResource(category.icon);
                for(var index in category.banners){
                    if(category.banners[index] && !this.createObjectURL(category.banners[index])) category.banners[index] = await api.getResource(category.banners[index]);
                }
            }
            catch(error){
                console.error(error);
                // alert(error.response ? error.response.data.message : error.message);
            }
            this.category = category;
        },

        /////////////////////////////////////////
        // 카테고리 수정
        /////////////////////////////////////////
        async update(category) {
            await api.console.shop.categories.put(category);

            alert("저장되었습니다");
            await this.search();
        },

        /////////////////////////////////////////
        // 카테고리 삭제
        /////////////////////////////////////////
        async remove(category) {
            try {
                this.loading = true;

                await api.console.shop.categories.delete(category);
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        sort(categories, _parents = [], depth = 1) {
            for(var category of categories) {
                category.sequence = categories.indexOf(category);
                category.depth = depth;
                category._parents = _parents;

                if (category.children) {
                    category._children = category.children.map((child) => { return child._id });
                    this.sort(category.children, [].concat(category._parents, category._id), depth + 1);
                }
                api.console.shop.categories.put(category);
            }
        },

        insertBanner(banner){
            this.category.banners.push(banner);
        },

        removeBanner(banner){
            this.category.banners.splice(this.category.banners.indexOf(banner), 1);
            this.category.banners.push(undefined);
        },

        insertPickup(pickup){
            this.category.pickups.push(pickup);
            this.category._pickups.push(pickup._id);
        },

        removePickup(pickup){
            this.category.pickups.splice(this.category.pickups.findIndex(item => item._id == pickup._id), 1);
            this.category._pickups.splice(this.category._pickups.findIndex(item => item == pickup._id), 1);
        },

        createObjectURL(file){
            try{
                return URL.createObjectURL(file);
            }
            catch(error){
                return null;
            }
        }
    },
    watch: {
        dialog(value){
            if(value) this.editItem = Object.assign({}, this.defaultItem);
        },
        categories() {
            if(!this.loading) this.sort(this.categories);
        }
    }
}
</script>
