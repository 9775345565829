var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', [_vm._v("회사소개 관리")]), _vm.setting ? [_c('img', {
    staticStyle: {
      "width": "500px"
    },
    attrs: {
      "src": _vm.fileUrl ? _vm.fileUrl : _vm.setting.site.contentImage
    }
  })] : _vm._e(), _c('v-row', [_c('v-col', [_c('v-textarea', {
    model: {
      value: _vm.setting.site.contentOne,
      callback: function ($$v) {
        _vm.$set(_vm.setting.site, "contentOne", $$v);
      },
      expression: "setting.site.contentOne"
    }
  })], 1), _c('v-col', [_c('v-textarea', {
    model: {
      value: _vm.setting.site.contentTwo,
      callback: function ($$v) {
        _vm.$set(_vm.setting.site, "contentTwo", $$v);
      },
      expression: "setting.site.contentTwo"
    }
  })], 1)], 1), _c('v-file-input', {
    on: {
      "change": _vm.testF
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }), _c('h4', [_vm._v("최적사이즈 816*972")]), _c('v-btn', {
    on: {
      "click": _vm.putSetting
    }
  }, [_vm._v("저장")])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }