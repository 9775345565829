<template>
<v-layout justify-center>
    <v-responsive max-width="1440px" width="100%" height="100%">
        <v-row class="mt-8" justify="center" align="center">
            <v-col cols="auto" class="pt-0 pb-0 headline text-start">매칭상담회 관리</v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="pt-0 pb-0">
                <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch">
                    <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                </v-btn>
            </v-col>
        </v-row>

        <v-expand-transition>
            <v-card v-show="showSearch" class="elevation-1 mx-auto">
                <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" sm="3" class="py-0">
                            <v-select v-model="filter.status" :items="statuses" item-text="text" item-value="value" label="상태" placeholder=" " hide-details></v-select>
                        </v-col>
                        <v-col cols="12" sm="3" class="py-0">
                        </v-col>
                    </v-row>
                    <v-row align="center" class="mt-3">
                        <v-col cols="12" sm="3" class="py-0">
                            <v-select v-model="filter.searchKey" :items="searchKeys" item-text="text" item-value="value" label="검색조건" placeholder=" " hide-details @input="filter.searchValue = null;"></v-select>
                        </v-col>
                        <v-col cols="12" sm="3" class="py-0">
                            <v-text-field v-model="filter.searchValue" label="검색어" placeholder=" " hide-details :disabled="!filter.searchKey" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded text @click="search">
                        <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>

        <v-row class="mt-4">
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <v-btn color="white" class="green--text px-2 ml-1" @click="excel">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table :headers="fairsHeaders" :items="fairs" disable-sort disable-pagination hide-default-footer class="elevation-1">
            <template v-slot:item.fair="{ item }">
                <v-row align="center" justify="center" class="d-none d-md-flex">
                    <v-col cols="12" md="auto" class="text-center">
                        <v-img :src="item.thumb ? item.thumb.ko : null" width="96" max-height="96" contain></v-img>
                    </v-col>
                    <v-col class="text-center">
                        <div class="d-inline-block" style="min-width: 100px">{{ item.name.ko }}</div>
                    </v-col>
                </v-row>
                <v-img :src="item.thumb.ko" width="96" max-height="96" contain class="d-md-none mx-auto"></v-img>
                <div style="max-width: 150px" class="d-md-none text-center">{{ item.name.ko }}</div>
            </template>
            <template v-slot:item.application="{ item }">
                <span v-if="item.application.enabled">{{ $dayjs(item.application.startedAt).format("YYYY-MM-DD") }} ~ {{ $dayjs(item.application.endedAt).format("YYYY-MM-DD") }}</span>
            </template>
            <template v-slot:item.matching="{ item }">
                <span>{{ $dayjs(item.matching.startedAt).format("YYYY-MM-DD") }} ~ {{ $dayjs(item.matching.endedAt).format("YYYY-MM-DD") }}</span>
            </template>
            <template v-slot:item.open="{ item }">
                <div>{{ $dayjs(item.open.startedAt).format("YYYY-MM-DD") }} ~ {{ $dayjs(item.open.endedAt).format("YYYY-MM-DD") }}</div>
            </template>
            <template v-slot:item.closedAt="{ item }">
                <div v-for="closedAt in item.closedAt">{{ closedAt }}</div>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small @click="edit(item)"> mdi-pencil </v-icon>
                <v-icon small @click="remove(item)" class="ml-1"> mdi-delete </v-icon>
            </template>
            <template v-slot:item.matchings="{item}">
                <v-icon small @click="matchings(item)">{{ mdiMagnify }}</v-icon>
            </template>
            <template v-slot:item.schedules="{item}">
                <v-icon small @click="schedules(item)">{{ mdiClock }}</v-icon>
            </template>
        </v-data-table>
        <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12 mx-4" @input="search(true)"></v-pagination>


        <v-btn color="pink" fab fixed right bottom dark @click="create">
            <v-icon>mdi-pencil</v-icon>
        </v-btn>
    </v-responsive>
</v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import download from "downloadjs";

import { mdiTableSearch } from '@mdi/js';
import { mdiMicrosoftExcel } from "@mdi/js";
import { mdiCalendarClock } from '@mdi/js';
import { mdiMagnify } from '@mdi/js';
import { mdiClock } from '@mdi/js';

export default {
    data() {
        return {
            mdiTableSearch,
            mdiMicrosoftExcel, // 엑셀아이콘
            mdiCalendarClock,
            mdiMagnify,
            mdiClock,

            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
                status: this.$route.query.status || null,
            },

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "상담회 명칭", value: "name.ko" },
            ],

            statuses: [
                { text: ":: 전체 ::", value: null },
                { text: "진행전", value: "진행전" },
                { text: "진행중", value: "진행중" },
                { text: "종료", value: "종료" },
            ],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            messageType: "search",
            message: null,

            fairs: [],
            summary: { totalCount: 0 },
            fairsHeaders: [
                {
                    text: "상담회",
                    align: "center",
                    value: "fair",
                },
                {
                    text: "제품접수 기간",
                    align: "center",
                    width: 200,
                    value: "application",
                },
                {
                    text: "바이어 상담신청 기간",
                    align: "center",
                    width: 200,
                    value: "matching",
                },
                {
                    text: "매칭상담회 기간(공개)",
                    align: "center",
                    width: 200,
                    value: "open",
                },
                {
                    text: "휴일",
                    align: "center",
                    width: 120,
                    value: "closedAt",
                },
                {
                    text: "상태",
                    align: "center",
                    width: 80,
                    value: "status",
                },
                {
                    text: "Actions",
                    value: "actions",
                    width: 60,
                    align: "center",
                },
                {
                    text: "매칭현황",
                    value: "matchings",
                    width: 60,
                    align: "center",
                },
                {
                    text: "확정일정",
                    value: "schedules",
                    width: 60,
                    align: "center",
                },
            ],

            levels: [],

            isWarningsItems: [{
                    text: "전체",
                    value: null
                },
                {
                    text: "주의회원",
                    value: true
                },
                {
                    text: "미주의회원",
                    value: false
                },
            ],
        };
    },
    created() {
        this.init();
    },
    methods: {
        download,

        async init() {
            this.search();
        },
        async search(routable = false) {

            if(routable){
                this.$router.push({
                    query: {
                        ...this.filter,
                        page: this.page
                    }
                });
            }

            let { summary, fairs } = await api.console.fairs.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.fairs = fairs;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },

        create(){
            this.$router.push(`/console/fairs/create`);
        },

        edit(fair) {
            this.$router.push(`/console/fairs/${fair._id}`);
        },

        matchings(fair){
            this.$router.push(`/console/fairs/${fair._id}/matchings`);
        },

        schedules(fair){
            this.$router.push(`/console/fairs/${fair._id}/schedules`);
        },

        async remove(fair){
            if(confirm("상담회를 삭제하시겠습니까?")){
                await api.console.fairs.delete(fair);
                alert("삭제되었습니다");
                this.search();
            }
        },

        async excel() {
            let { fairs } = await api.console.fairs.gets({
                filter: this.filter,
            });

            let rows = [];
            fairs.forEach(fair => {
                rows.push({
                    "상담회": fair.name?.ko,
                    "접수일자(셀러)": fair?.application?.enabled ? `${this.$dayjs(fair?.application?.startedAt).format("YYYY-MM-DD")} ~ ${this.$dayjs(fair?.application?.endedAt).format("YYYY-MM-DD")}` : '-',
                    "매칭일자(바이어)": `${this.$dayjs(fair?.matching?.startedAt).format("YYYY-MM-DD")} ~ ${this.$dayjs(fair?.matching?.endedAt).format("YYYY-MM-DD")}`,
                    "오픈일자(공개)": `${this.$dayjs(fair?.open?.startedAt).format("YYYY-MM-DD")} ~ ${this.$dayjs(fair?.open?.endedAt).format("YYYY-MM-DD")}`,
                    "휴일": `${fair.closedAt}`,
                    "상태": fair?.status,
                });
            });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "상담회목록");
            XLSX.writeFile(workbook, "상담회목록.xlsx");
        },
    },
};
</script>
