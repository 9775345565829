<template>
<v-layout justify-center>
    <v-responsive max-width="1280px" width="100%" height="100%">
        <v-row class="mt-8" justify="center" align="center">
            <v-col cols="auto" class="headline text-start">확정일정</v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
            </v-col>
        </v-row>

        <v-card v-if="fair" class="elevation-1 mx-auto">
            <v-card-title class="subtitle-2 font-weight-bold">매칭상담회</v-card-title>
            <v-divider/>
            <v-data-table :headers="fairsHeaders" :items="[fair]" disable-sort disable-pagination hide-default-footer class="elevation-1">
                <template v-slot:item.fair="{ item }">
                    <v-row align="center">
                        <v-col cols="auto">
                            <v-img :src="item.thumb.ko" width="96" max-height="96" contain></v-img>
                        </v-col>
                        <v-col class="text-start">
                            <span>{{ item.name.ko }}</span>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.application="{ item }">
                    <span v-if="item.application.enabled">{{ $dayjs(item.application.startedAt).format("YYYY-MM-DD") }} ~ {{ $dayjs(item.application.endedAt).format("YYYY-MM-DD") }}</span>
                </template>
                <template v-slot:item.matching="{ item }">
                    <span>{{ $dayjs(item.matching.startedAt).format("YYYY-MM-DD") }} ~ {{ $dayjs(item.matching.endedAt).format("YYYY-MM-DD") }}</span>
                </template>
                <template v-slot:item.open="{ item }">
                    <div>{{ $dayjs(item.open.startedAt).format("YYYY-MM-DD") }} ~ {{ $dayjs(item.open.endedAt).format("YYYY-MM-DD") }}</div>
                </template>
                <template v-slot:item.closedAt="{ item }">
                    <div v-for="closedAt in item.closedAt">{{ closedAt }}</div>
                </template>
            </v-data-table>
        </v-card>

        <v-row class="mt-4" align="end">
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <v-btn color="white" class="green--text px-2 ml-1" @click="excel">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table v-for="date in dates" v-show="1 < headers(date).length" :key="date" :headers="headers(date)" :items="datas(date)" disable-sort disable-pagination hide-default-footer class="elevation-1 mb-3"></v-data-table>

    </v-responsive>
</v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import dayjs from "dayjs";
import download from "downloadjs";

import { mdiTableSearch } from '@mdi/js';
import { mdiMicrosoftExcel } from "@mdi/js";
import { mdiCalendarClock } from '@mdi/js';
import { mdiMagnify } from '@mdi/js';
import { mdiClock } from '@mdi/js';

export default {
    data() {
        return {
            mdiTableSearch,
            mdiMicrosoftExcel, // 엑셀아이콘
            mdiCalendarClock,
            mdiMagnify,
            mdiClock,

            fair: null,
            fairsHeaders: [
                {
                    text: "상담회",
                    align: "center",
                    value: "fair",
                },
                {
                    text: "접수일자(셀러)",
                    align: "center",
                    width: 200,
                    value: "application",
                },
                {
                    text: "매칭일자(바이어)",
                    align: "center",
                    width: 200,
                    value: "matching",
                },
                {
                    text: "오픈일자(공개)",
                    align: "center",
                    width: 200,
                    value: "open",
                },
                {
                    text: "휴일",
                    align: "center",
                    width: 120,
                    value: "closedAt",
                },
                {
                    text: "상태",
                    align: "center",
                    width: 100,
                    value: "status",
                },
            ],

            filter: {
                status: "매칭확정",
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            matchings: [],
            matchingsHeaders: [
                {
                    text: "제품명",
                    align: "center",
                    value: "product",
                },
                {
                    text: "셀러",
                    align: "center",
                    value: "seller",
                },
                {
                    text: "바이어",
                    align: "center",
                    value: "buyer",
                },
                {
                    text: "매칭상태",
                    align: "center",
                    value: "status",
                },
            ],
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            let { fair } = await api.console.fairs.get({ _id: this.$route.params._fair });
            this.fair = fair;

            this.search();
        },
        async search(routable = false) {

            if(routable){
                this.$router.push({
                    query: {
                        ...this.filter,
                        page: this.page
                    }
                });
            }

            let { summary, matchings } = await api.console.fairs.matchings.gets(this.$route.params._fair, {
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.matchings = matchings;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },

        headers(date){
            let headers = [{ text: date, align: "center", value: "time" }];
            let max = this.datas(date).reduce((max, data) => max < Object.keys(data).length - 1 ? Object.keys(data).length - 1 : max, 0);
            for(let team = 1; team <= max; team++ ){
                headers.push({ text: `team${+team}`, align: "center", value: `team${+team}`});
            }
            return headers;
        },

        datas(date){
            let datas = []
            for(let time of this.times){
                let matchings = this.matchings.filter(matching => matching.schedules[0].date == date && matching.schedules[0].time == time);
                let data = { time };
                for(let team in matchings){
                    let matching = matchings[team];
                    let kind;
                    switch(matching.kind){
                        case "online": { kind = "온라인"; break; }
                        case "offline": { kind = "오프라인"; break; }
                    }
                    data[`team${+team + 1}`] = `[${kind}]\n 셀러: ${matching.seller.companyName.ko} / 바이어: ${matching.buyer.companyName.ko}`;
                }
                datas.push(data)
            }
            return datas;
        },

        async excel() {
            let workbook = new XLSX.utils.book_new();
            for(let date of this.dates){
                if(1 < this.headers(date).length){
                    let worksheet = XLSX.utils.json_to_sheet(this.datas(date));
                    XLSX.utils.book_append_sheet(workbook, worksheet, date);
                }
            }
            XLSX.writeFile(workbook, `${this.fair.name.ko}_확정일정_${this.$dayjs().format("YYYYMMDD")}.xlsx`);
        },
    },
    computed: {
        dates(){
            if(!this.fair) return;
            let dates = [];
            for(let date = dayjs(this.fair.open.startedAt); date.format("YYYY-MM-DD") != dayjs(this.fair.open.endedAt).add(1, "days").format("YYYY-MM-DD"); date = date.add(1, "days")){
                let dateFormat = date.format("YYYY-MM-DD");
                if(!this.fair.closedAt.includes(dateFormat)) dates.push(dateFormat);
            }
            return dates;
        },
        times(){
            return [
                "10:00 ~ 11:00",
                "11:00 ~ 12:00",
                "13:00 ~ 14:00",
                "14:00 ~ 15:00",
                "15:00 ~ 16:00",
                "17:00 ~ 18:00",
            ];
        }
    }
};
</script>
