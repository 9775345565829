var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "560"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.show ? _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1",
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v("신규 카테고리 등록")]), _c('v-card-text', [_c('category-select', {
    attrs: {
      "categories": _vm.categories,
      "return-object": "",
      "allowDepth": [1]
    },
    model: {
      value: _vm.editItem.parent,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "parent", $$v);
      },
      expression: "editItem.parent"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "카테고리 이름(한국어)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.name.ko,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.name, "ko", $$v);
      },
      expression: "editItem.name.ko"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "카테고리 이름(영어)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.name.en,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.name, "en", $$v);
      },
      expression: "editItem.name.en"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "카테고리 이름(중국어)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.name.cn,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.name, "cn", $$v);
      },
      expression: "editItem.name.cn"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "카테고리 코드",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.code,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "code", $$v);
      },
      expression: "editItem.code"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("등록")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }