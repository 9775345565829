<template>
    <v-dialog v-model="show" width="560">
        <v-card v-if="show">
            <v-card-title class="subtitle-1" primary-title>신규 카테고리 등록</v-card-title>
            <v-card-text>
                <category-select v-model="editItem.parent" :categories="categories" return-object :allowDepth="[1]"></category-select>
                <v-text-field v-model="editItem.name.ko" label="카테고리 이름(한국어)" placeholder=" " hide-details class="mt-3"></v-text-field>
                <v-text-field v-model="editItem.name.en" label="카테고리 이름(영어)" placeholder=" " hide-details class="mt-3"></v-text-field>
                <v-text-field v-model="editItem.name.cn" label="카테고리 이름(중국어)" placeholder=" " hide-details class="mt-3"></v-text-field>
                <v-text-field v-model="editItem.code" label="카테고리 코드" placeholder=" " hide-details class="mt-3"></v-text-field>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancel">취소</v-btn>
                <v-btn color="primary" text @click="save">등록</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import CategorySelect from "@/components/console/shop/categories/category-select.vue";

export default {
    components: {
        CategorySelect
    },
    data(){
        return {
            show: false,

            categories: [],

            editItem: null,
            defaultItem: {
                name: {
                    ko: null,
                    en: null,
                    cn: null,
                },
                code: null,
                parent: null,
            }
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { categories } = await api.console.shop.categories.gets({ params: { depth: 1 } });
            this.categories = categories;
        },
        open(){
            this.editItem = JSON.parse(JSON.stringify(this.defaultItem));
            this.show = true;
        },
        close(){
            this.editItem = null;
            this.show = false;
        },
        cancel(){
            this.close();
        },
        async save(){
            await api.console.shop.categories.post(this.editItem);
            
            alert("저장되었습니다");

            this.$emit("save");
            this.close();
            this.init();
        }
    }
}
</script>