<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-product-view">
            <div class="headline text-start mt-8">
                <span v-if="user._id">회원 상세보기</span>
                <span v-else>회원 등록</span>
            </div>

            <v-row>
                <v-col cols="12" md="8">
                    <v-card>
                        <v-card-title class="subtitle-2 font-weight-bold">기본정보</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.username" label="아이디" placeholder=" " hide-details readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                </v-col>
                            </v-row>

                            <v-row class="mt-4">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.name" label="이름(담당자)" placeholder=" " hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.phone" label="연락처(담당자)" placeholder=" " hide-details></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row class="mt-4">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.email" label="이메일(담당자)" placeholder=" " hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-select v-model="user._level" label="회원등급" :items="levels" item-text="name" item-value="_id" placeholder=" " hide-details></v-select>
                                </v-col>
                            </v-row>

                            <v-row class="mt-4">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.password" label="비밀번호" placeholder=" " hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.subPassword" label="서브비밀번호" placeholder=" " hide-details></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row class="mt-8">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.postcode" label="우편번호" placeholder=" " hide-details readonly @click="$refs.DaumPostcode.open()"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-4">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.address1" label="기본주소" placeholder=" " hide-details readonly @click="$refs.DaumPostcode.open()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.address2" label="상세주소" placeholder=" " hide-details></v-text-field>
                                </v-col>
                            </v-row>

                            <v-textarea v-model="user.note" label="메모" placeholder=" " class="mt-4"></v-textarea>
                        </v-card-text>
                    </v-card>

                    <buyer-details :user="user"></buyer-details>
                    <seller-details :user="user"></seller-details>

                </v-col>
                <v-col cols="12" md="4">
                    <v-card>
                        <v-card-title>
                            <span class="subtitle-2">주의회원</span>
                            <v-spacer/>
                            <v-switch v-model="user.isWarning" color="red lighten-1" dense hide-details class="mt-0 pa-0"></v-switch>
                        </v-card-title>
                        <v-card-text class="caption primary--text">
                            * 주의회원 체크시 문자와 메일이 발숭되지 않으니 주의 부탁드립니다
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center" class="mt-4">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
        <daum-postcode ref="DaumPostcode" @input="({ postcode, address }) => { user.postcode = postcode; user.address1 = address; }"></daum-postcode>
    </v-layout>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import BuyerDetails from "@/components/console/user/buyer-details.vue";
import SellerDetails from "@/components/console/user/seller-details.vue";

export default {
    components: {
        DaumPostcode,
        BuyerDetails,
        SellerDetails,
    },
    data() {
        return {
            levels: [],
            user: {
                _id: this.$route.params._user
            },
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.user._id){
                var { user } = await api.console.users.get({ _id: this.$route.params._user });
                this.user = user;
            }

            var { levels } = await api.console.levels.gets();
            this.levels = levels;
        },

        validate(){
            try{
                // if(!this.user.name) throw new Error("담당자 이름을 입력해주세요");
                // if(!this.user.email) throw new Error("담당자 이메일을 입력해주세요");

                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },

        async save(){
            if(this.validate()){
                if(this.user._id){
                    await api.console.users.put({
                        ...this.user,
                        password: this.user.password ? CryptoAES.encrypt(this.user.password) : undefined
                    });
                }
                else {
                    await api.console.users.post({
                        ...this.user,
                        username: this.user.email,
                        password: this.user.password ? CryptoAES.encrypt(this.user.password) : undefined
                    });
                }

                alert("저장되었습니다");
                this.$router.push(`/console/users`);
            }
        },
    }
}
</script>
