<template>
    <v-card>
        <v-card-text>
            <v-row align="center">
                <v-col sm="12" md="auto">
                    <v-radio-group v-model="target" dense>
                        <v-radio :label="`선택된 사용자에게 발송[${value.length}명]`" value="selected"></v-radio>
                        <v-radio :label="`검색된 사용자에게 발송[${summary.totalCount.format()}명]`" value="search"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col>
                    <v-text-field v-model="subject" label="메일 제목" placeholder=" " outlined hide-details></v-text-field>
                    <v-textarea v-model="html" label="메일 내용" placeholder=" " hide-details outlined class="mt-3"></v-textarea>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="primary" @click="send" class="mt-8">발송</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import api from "@/api";

export default {
    props: ["value", "summary"],
    data(){
        return {
            target: "selected",
            subject: null,
            html: null,

            filter: this.$attrs
        }
    },
    methods: {
        async send(){
            try{
                let users;
                switch(this.target){
                    case "selected": {
                        users = this.value;
                        break;
                    }
                    case "all": {
                        users = await api.console.users.gets({ headers: { limit: 0 }, params: this.filter });
                        break;
                    }
                }

                if(!users.length) throw new Error("발송할 회원이 없습니다");

                await api.console.email.post({
                    to: users.filter(user => !user.isWarning).map(user => user.email),
                    subject: this.subject,
                    html: this.html
                });

                alert("발송되었습니다");
            }
            catch(error){
                alert(error.message);
            }
        }
    }
}
</script>