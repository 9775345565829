<template>
    <component :is="display" />
</template>

<script>
export default {
    computed: {
        scope() {
            return this.$store.state.scope;
        },
        display() {
            return () => import("./form/FormList.vue");
        },
    },
};
</script>
