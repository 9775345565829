<template>
  <div>
    <h2>회사소개 관리</h2>
    <template v-if="setting">
        <img :src="fileUrl ? fileUrl : setting.site.contentImage"  style="width : 500px;"/>
        
    </template>

    <v-row>
      <v-col>
        <v-textarea v-model="setting.site.contentOne"/>
      </v-col>
      <v-col>
        <v-textarea v-model="setting.site.contentTwo"/>
      </v-col>
    </v-row>
    
    <v-file-input v-model="file" @change="testF"/>
    <h4>최적사이즈 816*972</h4>
    <v-btn @click="putSetting">저장</v-btn>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      setting: null,
      file: null,
      fileUrl : "",
    };
  },
  created() {
    this.getSetting();
  },
  methods: {
      testF() {
          this.fileUrl = window.URL.createObjectURL(event.target.files[0]);
      },
    async putSetting() {
      try {
        console.log({...this.setting})
        await api.console.setting.put({...this.setting});
        if(this.file) await api.console.setting.post("content", this.file);
        
      } catch (error) {
        this.handleError(error);
      }
    },
    async getSetting() {
      try {
        var { setting } = await api.console.setting.get({});
        this.setting = setting;
      } catch (error) {
        this.handleError(error);
      }
    },
    handleError(error) {
      console.error(error);
      alert(error.repsonse ? error.response.data.message : error.message);
    },
  },
};
</script>
