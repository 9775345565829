<template>
    <v-dialog v-model="show" max-width="800px">
        <v-card v-if="matching && consulting">
            <v-card-title class="subtitle-1 font-weight-bold">상담일지</v-card-title>
            <v-divider/>
            <v-card-text class="mt-3">
                <v-text-field label="작성일자" hide-details disabled readonly :value="$dayjs(consulting.writedAt).format('YYYY-MM-DD HH:mm:ss')"></v-text-field>
                <v-text-field label="셀러" hide-details readonly :value="matching.seller.companyName.ko" class="mt-3"></v-text-field>
                <v-text-field label="바이어" hide-details readonly :value="matching.buyer.companyName.ko" class="mt-3"></v-text-field>

                <v-text-field v-model="consulting.content1" label="1.주력품목(셀러)" hide-details readonly class="mt-3"></v-text-field>
                <v-text-field v-model="consulting.content2" label="2.주력시장 및 타겟(셀러)" hide-details readonly class="mt-3"></v-text-field>
                <v-text-field v-model="consulting.content3" label="3.상담상품 정보" hide-details readonly class="mt-3"></v-text-field>
                <v-textarea v-model="consulting.content4" label="4.거래 예상규모" readonly hide-details class="mt-3"></v-textarea>
                <v-textarea v-model="consulting.content5" label="5.상담내용" readonly hide-details class="mt-3"></v-textarea>

                <div class="mt-3">
                    <span class="caption d-block">취급의사</span>
                    <v-radio-group v-model="consulting.intention" row class="mt-0" dense readonly hide-details>
                        <v-radio label="있음" :value="true"></v-radio>
                        <v-radio label="없음" :value="false"></v-radio>
                    </v-radio-group>
                </div>

                <div class="mt-3">
                    <span class="caption">만족도</span>
                    <v-rating readonly :value="consulting.satisfaction" dense></v-rating>
                </div>

                <div class="mt-3">
                    <span class="caption d-block">후속진행</span>
                    <v-checkbox v-model="consulting.followUp" label="이메일" value="email" dense class="d-inline-block mt-0 mr-2"></v-checkbox>
                    <v-checkbox v-model="consulting.followUp" label="샘플구매" value="sample" dense class="d-inline-block mt-0 mr-2"></v-checkbox>
                    <v-checkbox v-model="consulting.followUp" label="기업방문" value="visit" dense class="d-inline-block mt-0 mr-2"></v-checkbox>
                    <v-checkbox v-model="consulting.followUp" label="의사없음" value="none" dense class="d-inline-block mt-0 mr-2"></v-checkbox>
                </div>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn text color="grey" @click="close">닫기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data(){
        return {
            matching: null,
            show: false
        };
    },
    methods: {
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        }
    },
    computed: {
        consulting(){
            return this.matching?.consulting;
        }
    }
}
</script>