<template>
<v-layout justify-center>
    <v-responsive max-width="1280px" width="100%" height="100%">
        <v-row class="mt-8" justify="center" align="center">
            <v-col cols="auto" class="headline text-start">매칭현황</v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
            </v-col>
        </v-row>

        <v-card v-if="fair" class="elevation-1 mx-auto">
            <v-card-title class="subtitle-2 font-weight-bold">매칭상담회</v-card-title>
            <v-divider/>
            <v-data-table :headers="fairsHeaders" :items="[fair]" disable-sort disable-pagination hide-default-footer class="elevation-1">
                <template v-slot:item.fair="{ item }">
                    <v-row align="center">
                        <v-col cols="auto">
                            <v-img :src="item.thumb.ko" width="96" max-height="96" contain></v-img>
                        </v-col>
                        <v-col class="text-start">
                            <span>{{ item.name.ko }}</span>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.application="{ item }">
                    <span v-if="item.application.enabled">{{ $dayjs(item.application.startedAt).format("YYYY-MM-DD") }} ~ {{ $dayjs(item.application.endedAt).format("YYYY-MM-DD") }}</span>
                </template>
                <template v-slot:item.matching="{ item }">
                    <span>{{ $dayjs(item.matching.startedAt).format("YYYY-MM-DD") }} ~ {{ $dayjs(item.matching.endedAt).format("YYYY-MM-DD") }}</span>
                </template>
                <template v-slot:item.open="{ item }">
                    <div>{{ $dayjs(item.open.startedAt).format("YYYY-MM-DD") }} ~ {{ $dayjs(item.open.endedAt).format("YYYY-MM-DD") }}</div>
                </template>
                <template v-slot:item.closedAt="{ item }">
                    <div v-for="closedAt in item.closedAt">{{ closedAt }}</div>
                </template>
            </v-data-table>
        </v-card>

        <v-row class="mt-4" align="end">
            <v-col cols="auto">
                <v-select v-model="filter.status" :items="statuses" item-text="text" item-value="value" hide-details class="mt-0" @input="search(true)"></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <v-btn color="white" class="green--text px-2 ml-1" @click="excel">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                </v-btn>
                <v-btn color="white" class="pink--text px-2 ml-1" @click="excelConsulting">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>상담일지 다운로드
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table :headers="matchingsHeaders" :items="matchings" disable-sort disable-pagination hide-default-footer class="elevation-1">
            <template v-slot:item.product="{item}">
                <v-row align="center">
                    <v-col cols="auto">
                        <v-img :src="item.product.thumb" max-width="96" max-height="96" contain/>
                    </v-col>
                    <v-col class="text-start">
                        {{ item.product.name[$i18n.locale] }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.seller="{item}">
                {{ item.seller.companyName[$i18n.locale] }}
            </template>
            <template v-slot:item.buyer="{item}">
                {{ item.buyer.companyName[$i18n.locale] }}
            </template>
            <template v-slot:item.status="{item}">
                <div>{{ item.status }}</div>
                <div>
                    <span v-if="item.kind == 'online'">온라인</span>
                    <span v-if="item.kind == 'offline'">오프라인</span>
                </div>
                <div v-for="(schedule, index) in item.schedules" :key="index">{{ schedule.date }} {{ schedule.time }}</div>
            </template>
            <template v-slot:item.consulting="{item}">
                <v-icon v-show="item.consulting.writedAt" small @click="consult(item)">{{ mdiFileDocument }}</v-icon>
            </template>]
        </v-data-table>
        <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12 mx-4" @input="search()"></v-pagination>
        <consulting-dialog ref="consultingDialog"></consulting-dialog>
    </v-responsive>
</v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import download from "downloadjs";

import { mdiTableSearch } from '@mdi/js';
import { mdiMicrosoftExcel } from "@mdi/js";
import { mdiCalendarClock } from '@mdi/js';
import { mdiMagnify } from '@mdi/js';
import { mdiClock } from '@mdi/js';
import { mdiFileDocument } from '@mdi/js';

import ConsultingDialog from "@/components/console/fair/consulting-dialog.vue";

export default {
    components: {
        ConsultingDialog
    },
    data() {
        return {
            mdiTableSearch,
            mdiMicrosoftExcel, // 엑셀아이콘
            mdiCalendarClock,
            mdiMagnify,
            mdiClock,
            mdiFileDocument,

            fair: null,
            fairsHeaders: [
                {
                    text: "상담회",
                    align: "center",
                    value: "fair",
                },
                {
                    text: "접수일자(셀러)",
                    align: "center",
                    width: 200,
                    value: "application",
                },
                {
                    text: "매칭일자(바이어)",
                    align: "center",
                    width: 200,
                    value: "matching",
                },
                {
                    text: "오픈일자(공개)",
                    align: "center",
                    width: 200,
                    value: "open",
                },
                {
                    text: "휴일",
                    align: "center",
                    width: 120,
                    value: "closedAt",
                },
                {
                    text: "상태",
                    align: "center",
                    width: 100,
                    value: "status",
                },
            ],

            filter: {
                status: this.$route.query.status || null,
            },

            statuses: [
                { text: ":: 전체 ::", value: null },
                { text: "매칭중", value: "매칭중" },
                { text: "매칭거절", value: "매칭거절" },
                { text: "매칭확정", value: "매칭확정" },
            ],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            matchings: [],
            matchingsHeaders: [
                {
                    text: "제품명",
                    align: "center",
                    value: "product",
                },
                {
                    text: "셀러",
                    align: "center",
                    value: "seller",
                },
                {
                    text: "바이어",
                    align: "center",
                    value: "buyer",
                },
                {
                    text: "매칭상태",
                    align: "center",
                    value: "status",
                },
                {
                    text: "보고서",
                    align: "center",
                    value: "consulting",
                },
            ],
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            let { fair } = await api.console.fairs.get({ _id: this.$route.params._fair });
            this.fair = fair;

            this.search();
        },
        async search(routable = false) {

            if(routable){
                this.$router.push({
                    query: {
                        ...this.filter,
                        page: this.page
                    }
                });
            }

            let { summary, matchings } = await api.console.fairs.matchings.gets(this.$route.params._fair, {
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.matchings = matchings;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },

        consult(matching){
            this.$refs.consultingDialog.matching = matching;
            this.$refs.consultingDialog.open();
        },

        async excel() {
            let { matchings } = await api.console.fairs.matchings.gets(this.$route.params._fair, {
                headers: { limit: 0 },
                params: this.filter
            });

            let rows = [];
            matchings.forEach(matching => {
                let kind;
                switch(matching.kind){
                    case "online": { kind = "온라인"; break; }
                    case "offline": { kind = "오프라인"; break; }
                }

                let schedules = "";
                for(let schedule of matching.schedules){
                    schedules += `${schedule.date} ${schedule.time}\n`;
                }

                rows.push({
                    "제품명": matching.product?.name?.ko,
                    "셀러": matching.seller.companyName?.ko,
                    "바이어": matching.buyer.companyName?.ko,
                    "매칭상태": matching.status,
                    "온라인/오프라인": kind,
                    "매칭상담시간": schedules,
                    "보고서 작성여부": matching.consulting.writedAt ? "작성" : "작성안함",
                });
            });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "매칭현황");
            XLSX.writeFile(workbook, `${this.fair.name.ko}_매칭현황_${this.$dayjs().format("YYYYMMDD")}.xlsx`);
        },

        async excelConsulting() {
            let { matchings } = await api.console.fairs.matchings.gets(this.$route.params._fair, {
                headers: { limit: 0 },
                params: {
                    "consulting.writedAt": true
                }
            });

            let rows = [];
            matchings.forEach(matching => {
                let kind;
                switch(matching.kind){
                    case "online": { kind = "온라인"; break; }
                    case "offline": { kind = "오프라인"; break; }
                }

                let schedules = "";
                for(let schedule of matching.schedules){
                    schedules += `${schedule.date} ${schedule.time}\n`;
                }

                let followUp;
                followUp = matching.consulting.followUp.map(value => {
                    switch(value){
                        case "email": return "이메일"; 
                        case "sample": return "샘플구매";
                        case "visit": return "기업방문";
                        case "none": return "의사없음";
                    }
                });

                rows.push({
                    "작성일자": this.$dayjs(matching.consulting.writedAt).format("YYYY-MM-DD HH:mm:ss"),
                    "셀러": matching.seller.companyName?.ko,
                    "바이어": matching.buyer.companyName?.ko,
                    "1.주력품목": matching.consulting.content1,
                    "2.주력시장 및 타겟": matching.consulting.content2,
                    "3.상담상품 정보": matching.consulting.content3,
                    "4.거래 예상규모": matching.consulting.content4,
                    "5.상담내용": matching.consulting.content5,
                    "취급의사": matching.consulting.intention ? "있음" : "없음",
                    "만족도": matching.consulting.satisfaction + "점",
                    "후속진행": followUp.join(", ")
                });
            });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "상담일지");
            XLSX.writeFile(workbook, `${this.fair.name.ko}_상담일지_${this.$dayjs().format("YYYYMMDD")}.xlsx`);
        },
    },
};
</script>
