var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "sm": "12",
      "md": "auto"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.target,
      callback: function ($$v) {
        _vm.target = $$v;
      },
      expression: "target"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": `선택된 사용자에게 발송[${_vm.value.length}명]`,
      "value": "selected"
    }
  }), _c('v-radio', {
    attrs: {
      "label": `검색된 사용자에게 발송[${_vm.summary.totalCount.format()}명]`,
      "value": "search"
    }
  })], 1)], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "문자 제목",
      "placeholder": " ",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.subject,
      callback: function ($$v) {
        _vm.subject = $$v;
      },
      expression: "subject"
    }
  }), _c('v-textarea', {
    staticClass: "mt-3",
    attrs: {
      "label": "문자 내용",
      "placeholder": " ",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.message,
      callback: function ($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.send
    }
  }, [_vm._v("발송")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }