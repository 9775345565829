<template>
  <div>
    <h2>인트로 관리</h2>
    <v-row>
      <v-col>
        <h2>PC</h2>
        <template v-if="setting">
            <v-img :src="fileUrl ? fileUrl : setting.site.introImage"  style="width : 500px;"/>
            <v-file-input v-model="file" @change="testF"/>
            <h4>최적사이즈 2560*1600</h4>
        </template>
      </v-col>
      <v-col>
        <h2>Mobile</h2>
        <template v-if="setting">
            <img :src="mfileUrl ? mfileUrl : setting.site.introMobile"  style="width : 500px;"/>
        </template>
        <v-file-input v-model="mfile" @change="testF2"/>
        <h4>최적사이즈 900*1600</h4>
      </v-col>
    </v-row>

    <v-btn @click="putSetting">저장</v-btn>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      setting: null,
      file: null,
      fileUrl : "",
      mfile: null,
      mfileUrl : "",

    };
  },
  created() {
    this.getSetting();
  },
  methods: {
      testF2() {
            this.mfileUrl = window.URL.createObjectURL(event.target.files[0]);
        },
      testF() {
          this.fileUrl = window.URL.createObjectURL(event.target.files[0]);
      },
    async putSetting() {
      try {
        if(this.file) await api.console.setting.post("intro", this.file);
        if(this.mfile) await api.console.setting.post("introM", this.mfile);
      } catch (error) {
        this.handleError(error);
      }
    },
    async getSetting() {
      try {
        var { setting } = await api.console.setting.get({});
        this.setting = setting;
      } catch (error) {
        this.handleError(error);
      }
    },
    handleError(error) {
      console.error(error);
      alert(error.repsonse ? error.response.data.message : error.message);
    },
  },
};
</script>
