var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', [_vm._v("인트로 관리")]), _c('v-row', [_c('v-col', [_c('h2', [_vm._v("PC")]), _vm.setting ? [_c('v-img', {
    staticStyle: {
      "width": "500px"
    },
    attrs: {
      "src": _vm.fileUrl ? _vm.fileUrl : _vm.setting.site.introImage
    }
  }), _c('v-file-input', {
    on: {
      "change": _vm.testF
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }), _c('h4', [_vm._v("최적사이즈 2560*1600")])] : _vm._e()], 2), _c('v-col', [_c('h2', [_vm._v("Mobile")]), _vm.setting ? [_c('img', {
    staticStyle: {
      "width": "500px"
    },
    attrs: {
      "src": _vm.mfileUrl ? _vm.mfileUrl : _vm.setting.site.introMobile
    }
  })] : _vm._e(), _c('v-file-input', {
    on: {
      "change": _vm.testF2
    },
    model: {
      value: _vm.mfile,
      callback: function ($$v) {
        _vm.mfile = $$v;
      },
      expression: "mfile"
    }
  }), _c('h4', [_vm._v("최적사이즈 900*1600")])], 2)], 1), _c('v-btn', {
    on: {
      "click": _vm.putSetting
    }
  }, [_vm._v("저장")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }