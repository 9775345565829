<template>
  <div>
    <v-row>
        <v-col>
            <h2>회사연혁 First Section</h2>
            <v-data-table :headers="headers" :items="setting.site.historyOne">
                <template v-slot:body="props">
                    <draggable v-model="setting.site.historyOne" tag="tbody">
                        <tr v-for="item,index in props.items" :key="item.id">
                            <td>
                                <v-text-field v-model="item.year" hide-details />
                            </td>

                            <td>
                                <v-text-field v-model="item.enContent"/>
                            </td>

                            <td>
                                <v-text-field v-model="item.krContent"/>
                            </td>

                            <td>
                                <v-btn @click="setting.site.historyOne.splice(setting.site.historyOne.indexOf(item),1)">삭제</v-btn>
                            </td>
                        </tr>
                    </draggable>
                </template>
            </v-data-table>
            <v-btn @click="addData('One')">추가</v-btn>
        </v-col>
        <v-col>
            <h2>회사연혁 Second Section</h2>
            <v-data-table :headers="headers" :items="setting.site.historyTwo">
                <template v-slot:body="props">
                    <draggable v-model="setting.site.historyTwo" tag="tbody">
                        <tr v-for="item,index in props.items" :key="item.id">
                            <td>
                                <v-text-field v-model="item.year" hide-details />
                            </td>

                            <td>
                                <v-text-field v-model="item.enContent"/>
                            </td>

                            <td>
                                <v-text-field v-model="item.krContent"/>
                            </td>

                            <td>
                                <v-btn @click="setting.site.historyTwo.splice(setting.site.historyTwo.indexOf(item),1)">삭제</v-btn>
                            </td>
                        </tr>
                    </draggable>
                </template>
            </v-data-table>
            <v-btn @click="addData('Two')">추가</v-btn>
        </v-col>

    </v-row>

    <v-btn @click="putSetting">저장</v-btn>
  </div>
</template>

<script>
import api from "@/api";
import draggable from "vuedraggable";

export default {
    components : {
        draggable
    },
  data() {
    return {
        tests: [{ message: 1}, { message: 2}, { message: 3 }],
      setting: null,

      headers: [
        
        { text: "년도", align: "center", width: 200, value: "year", },
        { text: "한글내용", align: "center", width: 200, value: "enContent", },
        { text: "영문내용", align: "center", width: 200, value: "krContent", },
        { text: "기능", align: "center", width: 200, value: "edit", },
      ],

      childData : null,

      data: {
        year: "",
        enContent: "",
        krContent: "",
      },
    };
  },
  created() {
    this.getSetting();
  },
  methods: {
    addData(type) {
        if(type == 'One') {
            var obj = {...this.data, id : this.setting.site.historyOne.length};
            this.setting.site.historyOne.splice(0,0,obj)
        }else {
            var obj = {...this.data, id : this.setting.site.historyTwo.length};
            this.setting.site.historyTwo.splice(0,0,obj)
        }
    },
    remove(history){
        let index = this.setting.site.history.indexOf(history);
        let count = 1;
        this.setting.site.historyOne.splice(this.setting.site.history.indexOf(item),1);
    },
    async putSetting() {
      try {
        console.log({ ...this.setting });
        await api.console.setting.put({ ...this.setting });
      } catch (error) {
        this.handleError(error);
      }
    },
    async getSetting() {
      try {
        var { setting } = await api.console.setting.get({});
        
        this.setting = setting;
        
      } catch (error) {
        this.handleError(error);
      }
    },
    handleError(error) {
      console.error(error);
      alert(error.repsonse ? error.response.data.message : error.message);
    },
  },
};
</script>
