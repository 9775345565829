<template>
<v-layout justify-center>
    <v-responsive max-width="1280px" width="100%" height="100%">
        <v-row class="mt-8" justify="center" align="center">
            <v-col cols="auto" class="pt-0 pb-0 headline text-start">회원 목록</v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="pt-0 pb-0">
                <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch">
                    <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                </v-btn>
            </v-col>
        </v-row>

        <v-expand-transition>
            <v-card v-show="showSearch" class="elevation-1 mx-auto">
                <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field v-model="filter['companyName.ko']" hide-details type="text" label="기업명" placeholder=" " @keydown.enter="search" />
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field v-model="filter.phone" hide-details type="text" label="연락처" placeholder=" " @keydown.enter="search" />
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <v-col cols="12" sm="6" class="py-0">
                            <v-select v-model="filter._level" :items="[{ name: '전체', _id: null }].concat(levels)" item-text="name" item-value="_id" hide-details label="회원등급" placeholder=" "></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-select v-model="filter.isWarning" :items="isWarningsItems" item-text="text" item-value="value" hide-details label="주의회원" placeholder=" "></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded text @click="search">
                        <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>

        <div class="primary--text caption mt-8">★★ 바이어 / 셀러 DB 등록 시 양식을 다운로드 받아서 DB를 작성해주세요</div>
        <div class="primary--text caption mt-1">★★ 바이어 / 셀러 등록 전 반드시 중복 체크를 먼저 진행해 주세요!</div>
        <v-row>
            <v-col cols="auto">
                <v-btn color="white" class="pink--text px-2 ml-1" @click="$refs.buyerInput.$el.getElementsByTagName('input')[0].click()">
                    <v-file-input accept=".xlsx" ref="buyerInput" class="d-none" @change="file => upload(file, 'buyer')"/>
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>바이어 업로드
                </v-btn>
                <v-btn color="white" class="green--text px-2 ml-1" @click="download('/assets/excel/buyer-example.xlsx')">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>바이어 양식 다운로드
                </v-btn>
                <v-btn color="white" class="pink--text px-2 ml-1" @click="$refs.sellerInput.$el.getElementsByTagName('input')[0].click()">
                    <v-file-input accept=".xlsx" ref="sellerInput" class="d-none" @change="file => upload(file, 'seller')" />
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>셀러 업로드
                </v-btn>
                <v-btn color="white" class="green--text px-2 ml-1" @click="download('/assets/excel/seller-example.xlsx')">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>셀러 양식 다운로드
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <v-btn color="white" class="green--text px-2 ml-1" @click="excel">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table v-model="selected" :headers="usersHeaders" :items="users" show-select disable-sort disable-pagination hide-default-footer class="elevation-1">
            <template v-slot:item.createdAt="{ item }">
                <span v-if="item.createdAt">{{ item.createdAt.toDateTime() }}</span>
                <span v-else>-</span>
            </template>
            <template v-slot:item.companyName="{ item }">
                <span v-if="item.companyName">{{ item.companyName.ko }}</span>
                <span v-else>-</span>
            </template>
            <template v-slot:item.phone="{ item }">
                <span v-if="item.phone">{{ item.phone.phoneNumberFormat() }}</span>
                <span v-else>-</span>
            </template>
            <template v-slot:item.email="{ item }">
                <span v-if="item.email">{{ item.email }}</span>
                <span v-else>-</span>
            </template>
            <template v-slot:item.level="{ item }">
                <span v-if="item.level">{{ item.level.name }}</span>
                <span v-else>-</span>
            </template>
            <template v-slot:item.isWarning="{ item }">
                <v-row justify="center" align="center">
                    <v-col cols="auto" class="pa-0">
                        <v-switch v-model="item.isWarning" color="red lighten-1" hide-details dens class="mt-0" @change="updateUser({ _id: item._id, isWarning: item.isWarning })"></v-switch>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.isApproval="{ item }">
                <v-row justify="center" align="center">
                    <v-col cols="auto" class="pa-0">
                        <v-switch v-model="item.isApproval" color="primary" hide-details dens class="mt-0" @change="updateUser({ _id: item._id, isApproval: item.isApproval })"></v-switch>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.orderPrice="{ item }">
                <span v-if="item.orderPrice">{{ item.orderPrice.format() }}</span>
                <span v-else>-</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small @click="edit(item)"> mdi-pencil </v-icon>
            </template>
        </v-data-table>
        <v-row>
            <v-spacer/>
            <v-col cols="auto" class="caption primary--text">* 주의회원 체크시 문자와 메일이 발숭되지 않으니 주의 부탁드립니다</v-col>
        </v-row>
        <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mx-4" @input="search()"></v-pagination>

        <v-tabs v-model="tabIndex" background-color="transparent">
            <v-tab>문자발송</v-tab>
            <v-tab>메일발송</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabIndex" class="mb-6">
            <v-tab-item :key="0">
                <message-post-card v-model="selected" :summary="summary" v-bind="filter"></message-post-card>
            </v-tab-item>
            <v-tab-item :key="1">
                <email-post-card v-model="selected" :summary="summary" v-bind="filter"></email-post-card>
            </v-tab-item>
        </v-tabs-items>

        <v-btn color="pink" fab fixed right bottom dark @click="create">
            <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <user-uploader ref="uploader" @save="search"></user-uploader>
    </v-responsive>
</v-layout>
</template>

<script>
import XLSX from "xlsx";
import download from "downloadjs";
import api from "@/api";
import UserUploader from "@/components/console/user/user-uploader.vue";
import MessagePostCard from "@/components/console/user/message-post-card.vue";
import EmailPostCard from "@/components/console/user/email-post-card.vue";

import {
    mdiMicrosoftExcel
} from "@mdi/js";

export default {
    components: {
        UserUploader,
        MessagePostCard,
        EmailPostCard
    },
    data() {
        return {
            mdiMicrosoftExcel, // 엑셀아이콘

            showSearch: true,

            filter: {
                name: this.$route.query.name || null,
                "companyName.ko": this.$route.query["companyName.ko"] || null,
                phone: this.$route.query.phone || null,
                _level: this.$route.query._level || null,
                isWarning: JSON.parse(this.$route.query.isWarning || "null"),
                isApproval: JSON.parse(this.$route.query.isApproval || "null"),
                isWithdrawal: JSON.parse(this.$route.query.isWithdrawal || "null"),
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            tabIndex: 0,
            messageType: "email",
            messageTypes: [
                { text: "이메일 발송", value: "email" },
                { text: "SMS 발송", value: "sms" }
            ],
            messageTarget: "selected",
            messageSubject: "",
            message: null,

            selected: [],
            users: [],
            summary: { totalCount: 0 },
            usersHeaders: [
                {
                    text: "사용자(담당자) 이름",
                    align: "center",
                    width: 200,
                    value: "name",
                },
                {
                    text: "기업명",
                    align: "center",
                    width: 200,
                    value: "companyName",
                },
                {
                    text: "아이디",
                    align: "center",
                    width: 200,
                    value: "username",
                },
                {
                    text: "연락처(담당자)",
                    align: "center",
                    width: 128,
                    value: "phone",
                },
                {
                    text: "이메일(담당자)",
                    align: "center",
                    width: 128,
                    value: "email",
                },
                {
                    text: "등급",
                    align: "center",
                    width: 140,
                    value: "level",
                },
                {
                    text: "주의회원",
                    width: 100,
                    align: "center",
                    value: "isWarning",
                },
                // {
                //     text: "가입승인",
                //     width: 100,
                //     align: "center",
                //     value: "isApproval",
                // },
                {
                    text: "가입일자",
                    align: "center",
                    width: 160,
                    value: "createdAt",
                },
                {
                    text: "Actions",
                    value: "actions",
                    width: 100,
                    align: "center",
                },
            ],

            levels: [],

            isWarningsItems: [{
                    text: "전체",
                    value: null
                },
                {
                    text: "주의회원",
                    value: true
                },
                {
                    text: "미주의회원",
                    value: false
                },
            ],
        };
    },
    created() {
        this.init();
    },
    methods: {
        download,

        async init() {
            
            try {
                var {
                    levels
                } = await api.console.levels.gets();
                this.levels = levels;

                this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            try {
                this.$router.push({
                    query: Object.assignDefined({}, this.$route.query, this.filter, {
                        page: this.page,
                    }),
                });

                var {
                    summary,
                    users
                } = await api.console.users.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.users = users;
                this.summary = summary;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },

        create(){
            this.$router.push(`/console/users/create`);
        },

        edit(user) {
            this.$router.push({
                path: `/console/users/${user._id}`,
            });
        },

        async updateUser(user) {
            try {
                await api.console.users.put(user);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },

        async excel() {
            try {
                var {
                    users
                } = await api.console.users.gets({
                    headers: {
                        limit: 0,
                    },
                    params: this.filter,
                });

                var rows = [];
                users.forEach((user) => {
                    rows.push({
                        "아이디": user?.username,
                        "서브비밀번호": user?.subPassword,
                        "사용자 이름": user?.name,
                        "연락처": user?.phone,
                        "이메일": user?.email,
                        "회원등급": user?.level?.name ,
                        "주의회원여부": user?.isWarning ? "주의" : "-",
                        "가입일자": user?.createdAt?.toDateTime(),
                    });
                });

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "회원목록.xlsx");
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },

        async sendemail(){
            try{
                var users;
                switch(this.messageTarget){
                    case "search": {
                        users = (await api.console.users.gets({
                            params: this.filter,
                        })).users;
                        break;
                    }
                    case "selected": {
                        if(!this.selected.length) throw new Error("사용자를 선택해주세요");
                        users = this.selected;
                        break;
                    }
                }
    
                var { success } = await api.v1.message.post({
                    emails: users.map(user => user.email),
                    subject: this.messageSubject,
                    message: this.message
                });
    
                if(success) alert("발송되었습니다");
            }
            catch(error){
                alert(error.message);
            }
        },

        upload(file, type){
            if(!file) return;

            let reader = new FileReader();
            reader.onload = () => {
                var workSheets = [];
                var workBook = XLSX.read(reader.result, { type: 'binary' });
                workBook.SheetNames.forEach(sheetName => {
                    let sheet = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
                    workSheets.push(sheet);
                });
                this.$refs.uploader.excelSheet = workSheets[0]; // 첫번째 시트
                this.$refs.uploader.type = type;
                this.$refs.uploader.open();
            };
            reader.readAsBinaryString(file);
        }
    },
};
</script>
