var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1200px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "headline text-start mt-8"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.fair._id ? "매칭상담회 상세보기" : "매칭상담회 등록"))])]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', {
    staticClass: "mt-0",
    attrs: {
      "items": _vm.locales,
      "item-text": "text",
      "item-value": "value",
      "label": "언어",
      "placeholder": " ",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.$i18n.locale,
      callback: function ($$v) {
        _vm.$set(_vm.$i18n, "locale", $$v);
      },
      expression: "$i18n.locale"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-row', {
    attrs: {
      "align": "start"
    }
  }, [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")])])], 1)], 1), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.thumb ? _c('v-img', {
    attrs: {
      "src": _vm.thumb,
      "width": "128",
      "height": "128"
    }
  }) : _c('v-responsive', {
    attrs: {
      "width": "128",
      "height": "128"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0 grey lighten-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    attrs: {
      "label": "썸네일",
      "show-size": "",
      "persistent-hint": "",
      "hint": "350*250(px)",
      "dense": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.fair.thumb[_vm.$i18n.locale],
      callback: function ($$v) {
        _vm.$set(_vm.fair.thumb, _vm.$i18n.locale, $$v);
      },
      expression: "fair.thumb[$i18n.locale]"
    }
  })], 1)], 1), _c('v-text-field', {
    attrs: {
      "label": "소제목",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.fair.title[_vm.$i18n.locale],
      callback: function ($$v) {
        _vm.$set(_vm.fair.title, _vm.$i18n.locale, $$v);
      },
      expression: "fair.title[$i18n.locale]"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "상담회 명칭",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.fair.name[_vm.$i18n.locale],
      callback: function ($$v) {
        _vm.$set(_vm.fair.name, _vm.$i18n.locale, $$v);
      },
      expression: "fair.name[$i18n.locale]"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "요약설명",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.fair.summary[_vm.$i18n.locale],
      callback: function ($$v) {
        _vm.$set(_vm.fair.summary, _vm.$i18n.locale, $$v);
      },
      expression: "fair.summary[$i18n.locale]"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-card', [_c('v-card-title', {
    class: {
      'pb-0': _vm.fair.application.enabled
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "subtitle-2 py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("매칭상담회 진행 방식")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v("(전체참여/선택참여)")])]), _c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0 d-inline-block",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.fair.application.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.fair.application, "enabled", $$v);
      },
      expression: "fair.application.enabled"
    }
  })], 1)], 1)], 1), _vm.fair.application.enabled ? _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pb-0"
  }, [_c('v-date-field', {
    attrs: {
      "label": "제품접수 시작일",
      "hide-details": "",
      "disabled": !_vm.fair.application.enabled
    },
    model: {
      value: _vm.fair.application.startedAt,
      callback: function ($$v) {
        _vm.$set(_vm.fair.application, "startedAt", $$v);
      },
      expression: "fair.application.startedAt"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("~")]), _c('v-col', {
    staticClass: "pb-0"
  }, [_c('v-date-field', {
    attrs: {
      "label": "제품접수 종료일",
      "hide-details": "",
      "disabled": !_vm.fair.application.enabled
    },
    model: {
      value: _vm.fair.application.endedAt,
      callback: function ($$v) {
        _vm.$set(_vm.fair.application, "endedAt", $$v);
      },
      expression: "fair.application.endedAt"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-card-text', [_c('div', {
    staticClass: "caption primary--text d-block"
  }, [_vm._v("* Off – 바이어가 전체 DB 검토 후 신청하는 형태")]), _c('div', {
    staticClass: "caption primary--text d-block"
  }, [_vm._v("* ON – 셀러가 1차 신청 후 바이어가 신청 셀러 DB 검토하는 형태")])])], 1), _c('v-card', {
    staticClass: "mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 pb-0"
  }, [_vm._v("바이어 상담신청 기간")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pb-0"
  }, [_c('v-date-field', {
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.fair.matching.startedAt,
      callback: function ($$v) {
        _vm.$set(_vm.fair.matching, "startedAt", $$v);
      },
      expression: "fair.matching.startedAt"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("~")]), _c('v-col', {
    staticClass: "pb-0"
  }, [_c('v-date-field', {
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.fair.matching.endedAt,
      callback: function ($$v) {
        _vm.$set(_vm.fair.matching, "endedAt", $$v);
      },
      expression: "fair.matching.endedAt"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 pb-0"
  }, [_vm._v("매칭상담회 기간(공개)")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pb-0"
  }, [_c('v-date-field', {
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.fair.open.startedAt,
      callback: function ($$v) {
        _vm.$set(_vm.fair.open, "startedAt", $$v);
      },
      expression: "fair.open.startedAt"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("~")]), _c('v-col', {
    staticClass: "pb-0"
  }, [_c('v-date-field', {
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.fair.open.endedAt,
      callback: function ($$v) {
        _vm.$set(_vm.fair.open, "endedAt", $$v);
      },
      expression: "fair.open.endedAt"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 pb-0"
  }, [_vm._v(" 휴일 "), _c('v-spacer'), _c('span', {
    staticClass: "caption primary--text"
  }, [_vm._v("* 페어가 진행중이지만 해당 날짜를 매칭 스케줄에서 제외합니다")])], 1), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pb-0"
  }, [_c('v-date-field', {
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.closedAt,
      callback: function ($$v) {
        _vm.closedAt = $$v;
      },
      expression: "closedAt"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.closedAt && _vm.fair.closedAt.indexOf(_vm.closedAt) < 0 ? _vm.fair.closedAt.push(_vm.closedAt) : null;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v("추가")], 1)], 1)], 1), _vm.fair.closedAt.length ? _c('div', {
    staticClass: "mt-3"
  }, _vm._l(_vm.fair.closedAt, function (closedAt) {
    return _c('v-chip', {
      key: closedAt,
      staticClass: "mr-2",
      attrs: {
        "color": "primary",
        "close": "",
        "close-icon": "delete"
      },
      on: {
        "click:close": function ($event) {
          _vm.fair.closedAt.splice(_vm.fair.closedAt.indexOf(closedAt), 1);
        }
      }
    }, [_vm._v(_vm._s(closedAt))]);
  }), 1) : _vm._e()], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("상세정보")]), _c('v-card-text', [_c('div', {
    staticClass: "editor",
    class: {
      hidden: _vm.$i18n.locale != 'ko'
    }
  }, [_c('naver-smarteditor', {
    model: {
      value: _vm.fair.content.ko,
      callback: function ($$v) {
        _vm.$set(_vm.fair.content, "ko", $$v);
      },
      expression: "fair.content.ko"
    }
  })], 1), _c('div', {
    staticClass: "editor",
    class: {
      hidden: _vm.$i18n.locale != 'en'
    }
  }, [_c('naver-smarteditor', {
    model: {
      value: _vm.fair.content.en,
      callback: function ($$v) {
        _vm.$set(_vm.fair.content, "en", $$v);
      },
      expression: "fair.content.en"
    }
  })], 1), _c('div', {
    staticClass: "editor",
    class: {
      hidden: _vm.$i18n.locale != 'cn'
    }
  }, [_c('naver-smarteditor', {
    model: {
      value: _vm.fair.content.cn,
      callback: function ($$v) {
        _vm.$set(_vm.fair.content, "cn", $$v);
      },
      expression: "fair.content.cn"
    }
  })], 1)])], 1), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1), _c('daum-postcode', {
    ref: "DaumPostcode",
    on: {
      "input": function (_ref) {
        var postcode = _ref.postcode,
          address = _ref.address;
        _vm.fair.postcode = postcode;
        _vm.fair.address1 = address;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }