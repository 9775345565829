var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.query._board ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("게시글 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("게시글 상세")]), _c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("이미지")]), _c('v-card-text', _vm._l(20, function (i) {
    return _c('v-row', {
      key: i,
      class: {
        'mt-3': 1 < i
      },
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "auto"
      }
    }, [_vm.board.images[i - 1] ? _c('v-img', {
      attrs: {
        "max-width": "96",
        "max-height": "96",
        "src": _vm.createObjectURL(_vm.board.images[i - 1]),
        "contain": ""
      }
    }) : _c('v-responsive', {
      staticClass: "grey lighten-2",
      attrs: {
        "width": "96",
        "height": "96"
      }
    }, [_c('v-row', {
      staticClass: "fill-height",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "black"
      }
    }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
      staticClass: "py-0"
    }, [_c('v-file-input', {
      staticClass: "mt-2 mx-3",
      attrs: {
        "label": `이미지 ${i}`,
        "placeholder": " ",
        "show-size": "",
        "accept": "image/*"
      },
      model: {
        value: _vm.board.images[i - 1],
        callback: function ($$v) {
          _vm.$set(_vm.board.images, i - 1, $$v);
        },
        expression: "board.images[i-1]"
      }
    })], 1)], 1);
  }), 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_vm.$route.params._board ? _c('v-text-field', {
    attrs: {
      "value": (_vm.board.writer || {}).name,
      "label": "작성자",
      "placeholder": " ",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  }) : _vm._e(), _vm.$route.params._board ? _c('v-datetime-field', {
    attrs: {
      "value": _vm.board.createdAt ? _vm.board.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "placeholder": " ",
      "hide-details": ""
    },
    on: {
      "input": function (value) {
        return _vm.board.createdAt = value;
      }
    }
  }) : _vm._e(), _c('v-select', {
    staticClass: "mt-3",
    attrs: {
      "items": _vm.categories,
      "item-text": "name",
      "item-value": "name",
      "label": "카테고리",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.board.category,
      callback: function ($$v) {
        _vm.$set(_vm.board, "category", $$v);
      },
      expression: "board.category"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "프로젝트명",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "의뢰인",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.board.client,
      callback: function ($$v) {
        _vm.$set(_vm.board, "client", $$v);
      },
      expression: "board.client"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "주소",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.board.address,
      callback: function ($$v) {
        _vm.$set(_vm.board, "address", $$v);
      },
      expression: "board.address"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "면적",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.board.area,
      callback: function ($$v) {
        _vm.$set(_vm.board, "area", $$v);
      },
      expression: "board.area"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "마감재",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.board.material,
      callback: function ($$v) {
        _vm.$set(_vm.board, "material", $$v);
      },
      expression: "board.material"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "벽",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.board.wall,
      callback: function ($$v) {
        _vm.$set(_vm.board, "wall", $$v);
      },
      expression: "board.wall"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "바닥",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.board.floor,
      callback: function ($$v) {
        _vm.$set(_vm.board, "floor", $$v);
      },
      expression: "board.floor"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "천정",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.board.ceiling,
      callback: function ($$v) {
        _vm.$set(_vm.board, "ceiling", $$v);
      },
      expression: "board.ceiling"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("내용1")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "id": "content",
      "rows": "3",
      "placeholder": " ",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("내용2")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "id": "content",
      "rows": "3",
      "placeholder": " ",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.board.content2,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content2", $$v);
      },
      expression: "board.content2"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1), _c('daum-postcode', {
    ref: "daumPostcode",
    on: {
      "change": _vm.changePostcode
    }
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }