<template>
    <v-layout justify-center>
        <v-responsive max-width="1200px" width="100%" height="100%" class="shop-product-view">
            <v-row class="headline text-start mt-8">
                <v-col cols="auto" class="py-0">
                    <span>{{ fair._id ? "매칭상담회 상세보기" : "매칭상담회 등록"}}</span>
                </v-col>
                <v-spacer/>
                <v-col cols="auto" class="py-0">
                    <v-select v-model="$i18n.locale" :items="locales" item-text="text" item-value="value" label="언어" placeholder=" " outlined dense hide-details class="mt-0"></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="7">
                    <v-card>
                        <v-card-title>
                            <v-row align="start">
                                <v-col cols="auto" class="pt-0">
                                    <span class="subtitle-2 font-weight-bold">기본정보</span>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0">
                                    <v-img v-if="thumb" :src="thumb" width="128" height="128"></v-img>
                                    <v-responsive v-else width="128" height="128">
                                        <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                            <v-icon>mdi-image</v-icon>
                                        </v-row>
                                    </v-responsive>
                                </v-col>
                                <v-col class="py-0">
                                    <v-file-input v-model="fair.thumb[$i18n.locale]" label="썸네일" show-size persistent-hint hint="350*250(px)" dense accept="image/*"></v-file-input>
                                </v-col>
                            </v-row>

                            <v-text-field v-model="fair.title[$i18n.locale]" label="소제목" placeholder=" " hide-details></v-text-field>
                            <v-text-field v-model="fair.name[$i18n.locale]" label="상담회 명칭" placeholder=" " hide-details class="mt-4"></v-text-field>
                            <v-text-field v-model="fair.summary[$i18n.locale]" label="요약설명" placeholder=" " hide-details class="mt-4"></v-text-field>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="5">
                    <v-card>
                        <v-card-title :class="{'pb-0': fair.application.enabled}">
                            <v-row align="center">
                                <v-col cols="auto" class="subtitle-2 py-0">매칭상담회 진행 방식</v-col>
                                <v-spacer/>
                                <v-col cols="auto" class="py-0 pr-0">
                                    <div class="caption">(전체참여/선택참여)</div>
                                </v-col>
                                <v-col cols="auto" class="py-0 pr-0">
                                    <v-switch v-model="fair.application.enabled" dense class="mt-0 d-inline-block" hide-details></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text v-if="fair.application.enabled">
                            <v-row align="center" justify="center">
                                <v-col class="pb-0"><v-date-field v-model="fair.application.startedAt" label="제품접수 시작일" hide-details :disabled="!fair.application.enabled"></v-date-field></v-col>
                                <v-col cols="auto" class="pa-0">~</v-col>
                                <v-col class="pb-0"><v-date-field v-model="fair.application.endedAt" label="제품접수 종료일" hide-details :disabled="!fair.application.enabled"></v-date-field></v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text>
                            <div class="caption primary--text d-block">* Off – 바이어가 전체 DB 검토 후 신청하는 형태</div>
                            <div class="caption primary--text d-block">* ON – 셀러가 1차 신청 후 바이어가 신청 셀러 DB 검토하는 형태</div>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-3">
                        <v-card-title class="subtitle-2 pb-0">바이어 상담신청 기간</v-card-title>
                        <v-card-text>
                            <v-row align="center" justify="center">
                                <v-col class="pb-0"><v-date-field v-model="fair.matching.startedAt" hide-details></v-date-field></v-col>
                                <v-col cols="auto" class="pa-0">~</v-col>
                                <v-col class="pb-0"><v-date-field v-model="fair.matching.endedAt" hide-details></v-date-field></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-3">
                        <v-card-title class="subtitle-2 pb-0">매칭상담회 기간(공개)</v-card-title>
                        <v-card-text>
                            <v-row align="center" justify="center">
                                <v-col class="pb-0"><v-date-field v-model="fair.open.startedAt" hide-details></v-date-field></v-col>
                                <v-col cols="auto" class="pa-0">~</v-col>
                                <v-col class="pb-0"><v-date-field v-model="fair.open.endedAt" hide-details></v-date-field></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-3">
                        <v-card-title class="subtitle-2 pb-0">
                            휴일 <v-spacer/><span class="caption primary--text">* 페어가 진행중이지만 해당 날짜를 매칭 스케줄에서 제외합니다</span></v-card-title>
                        <v-card-text>
                            <v-row align="center" justify="center">
                                <v-col class="pb-0"><v-date-field v-model="closedAt" hide-details></v-date-field></v-col>
                                <v-col cols="auto" class="pb-0"><v-btn small color="primary" @click="closedAt && fair.closedAt.indexOf(closedAt) < 0 ? fair.closedAt.push(closedAt) : null"><v-icon small class="mr-2">mdi-plus</v-icon>추가</v-btn></v-col>
                            </v-row>
                            <div v-if="fair.closedAt.length" class="mt-3">
                                <v-chip v-for="closedAt in fair.closedAt" :key="closedAt" color="primary" close close-icon="delete" class="mr-2" @click:close="fair.closedAt.splice(fair.closedAt.indexOf(closedAt),1)">{{ closedAt }}</v-chip>
                            </div>
                        </v-card-text>
                    </v-card>
                    
                </v-col>
            </v-row>

            <v-card class="mt-3">
                <v-card-title class="subtitle-2 font-weight-bold">상세정보</v-card-title>
                <v-card-text>
                    <div class="editor" :class="{ hidden: $i18n.locale != 'ko' }"><naver-smarteditor v-model="fair.content.ko"></naver-smarteditor></div>
                    <div class="editor" :class="{ hidden: $i18n.locale != 'en' }"><naver-smarteditor v-model="fair.content.en"></naver-smarteditor></div>
                    <div class="editor" :class="{ hidden: $i18n.locale != 'cn' }"><naver-smarteditor v-model="fair.content.cn"></naver-smarteditor></div>
                </v-card-text>
            </v-card>

            <v-row justify="center" class="mt-4">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
        <daum-postcode ref="DaumPostcode" @input="({ postcode, address }) => { fair.postcode = postcode; fair.address1 = address; }"></daum-postcode>
    </v-layout>
</template>

<script>
import api from "@/api";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        DaumPostcode,
        NaverSmarteditor,
        VDateField
    },
    data() {
        return {
            locales: [
                { text: "한국어", value: "ko" },
                { text: "영어", value: "en" },
                { text: "중국어", value: "cn" },
            ],

            fair: {
                _id: this.$route.params._fair,

                title: { ko: null, en: null, cn: null },
                name: { ko: null, en: null, cn: null },
                summary: { ko: null, en: null, cn: null },
                content: { ko: null, en: null, cn: null },
                thumb: { ko: null, en: null, cn: null },

                matching: {
                    startedAt: null,
                    endedAt: null,
                },

                application: {
                    enabled: false,
                    startedAt: null,
                    endedAt: null,
                },

                open: {
                    startedAt: null,
                    endedAt: null
                },

                closedAt: []
            },

            closedAt: null
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.fair._id){
                var { fair } = await api.console.fairs.get({ _id: this.$route.params._fair });
                this.fair = { ...this.fair, ...fair };
                if(this.fair.thumb.ko) this.fair.thumb.ko = await api.getResource(this.fair.thumb.ko);
                if(this.fair.thumb.en) this.fair.thumb.en = await api.getResource(this.fair.thumb.en);
                if(this.fair.thumb.cn) this.fair.thumb.cn = await api.getResource(this.fair.thumb.cn);
            }
        },

        validate(){
            try{
                if(!this.fair.name) throw new Error("상담회 명칭을 입력해주세요");
                if(!this.fair.matching.startedAt || !this.fair.matching.endedAt) throw new Error("상담회 일정을 선택해주세요");
                if(this.fair.application.enabled && (!this.fair.application.startedAt || !this.fair.application.endedAt)) throw new Error("작품 접수일정을 선택해주세요");

                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },

        async save(){
            if(this.validate()){
                let { fair } = this.fair._id ? await api.console.fairs.put(this.fair) : await api.console.fairs.post(this.fair);

                if(this.fair.thumb.ko) await api.console.fairs.thumb.post(fair._id, "ko", this.fair.thumb.ko);
                if(this.fair.thumb.en) await api.console.fairs.thumb.post(fair._id, "en", this.fair.thumb.en);
                if(this.fair.thumb.cn) await api.console.fairs.thumb.post(fair._id, "cn", this.fair.thumb.cn);

                alert("저장되었습니다");
                this.$router.push(`/console/fairs`);
            }
        },
    },
    computed: {
        thumb(){
            let thumb = this.fair.thumb[this.$i18n.locale];
            return thumb && typeof thumb == "object" ? URL.createObjectURL(thumb) : null;
        }
    }
}
</script>

<style>
.editor.hidden { position: absolute; width: 0; height: 0; overflow: hidden; }
</style>