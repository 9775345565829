<template>
    <component :is="skin" />
</template>

<script>
import BoardViewBasic from "./BoardViewBasic.vue";
import BoardViewArchive from "./BoardViewArchive.vue";
export default {
    components: {
        BoardViewBasic,
        BoardViewArchive
    },
    computed: {
        skin(){
            switch(this.$route.query.code){
                case "archive": return "boardViewArchive";
                default: {
                    return "boardViewBasic";
                }
            }
        }
    }
}
</script>