var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user && _vm.user.type == 'buyer' ? _c('v-card', {
    staticClass: "mt-3"
  }, [_c('v-card-title', {}, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 subtitle-2 font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 상세정보 ")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', {
    staticClass: "mt-0",
    attrs: {
      "items": _vm.locales,
      "item-text": "text",
      "item-value": "value",
      "label": "언어",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.locale,
      callback: function ($$v) {
        _vm.locale = $$v;
      },
      expression: "locale"
    }
  })], 1)], 1)], 1), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "기업명",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.companyName[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.user.companyName, _vm.locale, $$v);
      },
      expression: "user.companyName[locale]"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "시/도",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.sido,
      callback: function ($$v) {
        _vm.$set(_vm.user, "sido", $$v);
      },
      expression: "user.sido"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "시/구/군",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.gugun,
      callback: function ($$v) {
        _vm.$set(_vm.user, "gugun", $$v);
      },
      expression: "user.gugun"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "상세주소",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.companyAddress,
      callback: function ($$v) {
        _vm.$set(_vm.user, "companyAddress", $$v);
      },
      expression: "user.companyAddress"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "설립연도",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.foundedAt,
      callback: function ($$v) {
        _vm.$set(_vm.user, "foundedAt", $$v);
      },
      expression: "user.foundedAt"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "사업분야",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.businessAreas,
      callback: function ($$v) {
        _vm.$set(_vm.user, "businessAreas", $$v);
      },
      expression: "user.businessAreas"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "바이어 유형",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.kind,
      callback: function ($$v) {
        _vm.$set(_vm.user, "kind", $$v);
      },
      expression: "user.kind"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "대표자 이름",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.ceoName,
      callback: function ($$v) {
        _vm.$set(_vm.user, "ceoName", $$v);
      },
      expression: "user.ceoName"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "대표자 연락처",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.ceoPhone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "ceoPhone", $$v);
      },
      expression: "user.ceoPhone"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "대표자 이메일",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.ceoEmail,
      callback: function ($$v) {
        _vm.$set(_vm.user, "ceoEmail", $$v);
      },
      expression: "user.ceoEmail"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "담당자 이름",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "담당자 연락처",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.phone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "담당자 이메일",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "회사 소재국가",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.countryOfOrigin,
      callback: function ($$v) {
        _vm.$set(_vm.user, "countryOfOrigin", $$v);
      },
      expression: "user.countryOfOrigin"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "바이어 활동지역",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.activityAreas[_vm.locale],
      callback: function ($$v) {
        _vm.$set(_vm.user.activityAreas, _vm.locale, $$v);
      },
      expression: "user.activityAreas[locale]"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "주요 유통망(온라인)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.onlineCirculation,
      callback: function ($$v) {
        _vm.$set(_vm.user, "onlineCirculation", $$v);
      },
      expression: "user.onlineCirculation"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "주요 유통망(오프라인)",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.offlineCirculation,
      callback: function ($$v) {
        _vm.$set(_vm.user, "offlineCirculation", $$v);
      },
      expression: "user.offlineCirculation"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "회사소개",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.aboutUs,
      callback: function ($$v) {
        _vm.$set(_vm.user, "aboutUs", $$v);
      },
      expression: "user.aboutUs"
    }
  }), _c('div', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v("관심상품군")]), _vm._l(_vm.categories, function (category) {
    return _c('v-checkbox', {
      key: category._id,
      staticClass: "mt-0 mr-3 d-inline-block",
      attrs: {
        "value": category._id,
        "label": category.name.ko,
        "multiple": "",
        "dense": "",
        "hide-details": ""
      },
      model: {
        value: _vm.user.interestedGroups,
        callback: function ($$v) {
          _vm.$set(_vm.user, "interestedGroups", $$v);
        },
        expression: "user.interestedGroups"
      }
    });
  })], 2), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "소싱희망상품",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.sourcings,
      callback: function ($$v) {
        _vm.$set(_vm.user, "sourcings", $$v);
      },
      expression: "user.sourcings"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "주요경력",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.career,
      callback: function ($$v) {
        _vm.$set(_vm.user, "career", $$v);
      },
      expression: "user.career"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "홈페이지 주소",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.user.siteUrl,
      callback: function ($$v) {
        _vm.$set(_vm.user, "siteUrl", $$v);
      },
      expression: "user.siteUrl"
    }
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }